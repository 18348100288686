import React from 'react';

// Dependencies
import Chip from '@mui/material/Chip/Chip';
import Divider from '@mui/material/Divider/Divider';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu/Menu';

// Components
import Icon from 'components/core/icon';
import Text from 'components/core/text';

export type MenuItem = {
    label: string;
    onClick: () => void;
    className?: string;
    icon?: {
        color: string;
        name: string;
    };
    right?: string;
};

type MenuProps = {
    content: MenuItem[];
    emptyText?: string;
    footer?: MenuItem[];
    header?: React.ReactNode;
} & MuiMenuProps;

export const menuConfig: Pick<MenuProps, 'PaperProps' | 'anchorOrigin' | 'transformOrigin'> = {
    transformOrigin: { horizontal: 'right', vertical: 'top' },
    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    PaperProps: {
        style: {
            borderRadius: 20,
            boxShadow: 'unset',
            filter: 'drop-shadow(0px 20px 50px rgba(44, 63, 88, 0.15))'
        }
    }
};

const Menu = ({ header, content, footer, emptyText = 'Nenhum item encontrado', ...props }: MenuProps) => {
    const renderByType = (accessor: string, options?: MenuItem[]) => {
        if (!options) {
            return null;
        }

        return options?.map((item, index) => (
            <MenuItem key={`${accessor}_${index}`} onClick={item.onClick}>
                {item.icon && (
                    <ListItemIcon>
                        <Icon {...item.icon} width={16} height={16} />
                    </ListItemIcon>
                )}
                <Text as="span" variant="body.regular.2xs" className={`flex-1 ${item.className || 'text-base-700'}`}>
                    {item.label}
                </Text>
                {item.right && <Chip label={item.right} color="error" size="small" />}
            </MenuItem>
        ));
    };

    return (
        <MuiMenu {...props} {...menuConfig}>
            {header}
            {Boolean(header) && <Divider />}
            {Boolean(content?.length) || Boolean(footer?.length) ? (
                <div>
                    {renderByType('content', content)}
                    {Boolean(footer) && <Divider />}
                    {renderByType('footer', footer)}
                </div>
            ) : (
                <div className="px-4 py-2">
                    <Text as="p" className="text-base-500 italic">
                        {emptyText}
                    </Text>
                </div>
            )}
        </MuiMenu>
    );
};

export default Menu;
