import React, { useContext, useMemo } from 'react';

// Dependencies
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

// Components
import Details from './meeting';

// Helpers
import { formatDate } from 'utils/date';
import { MeetingPayload } from 'contexts/meeting-record/types';
import { MeetingRecordContext } from 'contexts/meeting-record';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';
import useGetPeopleByProjectOptions from 'services/queries/people/use-get-people-options-by-project';
import useCreateOrUpdateMeetingRecord from 'services/queries/projects/use-create-or-update-meeting-record';

const Meeting = () => {
    const navigate = useNavigate();
    const { meetingRecordId, projectId } = useParams();
    const { state, setContent } = useContext(MeetingRecordContext);
    const ctx = useOutletContext<OperationalDetailsPageContext | undefined>();

    const projectIdParam = useMemo(() => Number(projectId) || ctx?.project?.id, [projectId, ctx?.project?.id]);

    const { data: people = [], isLoading } = useGetPeopleByProjectOptions(projectIdParam);
    const { mutateAsync: createOrUpdateMeetingRecord, isLoading: isSubmitting } = useCreateOrUpdateMeetingRecord(meetingRecordId, projectIdParam);

    const handleSubmit = async (data: MeetingPayload) => {
        try {
            const payload = {
                dateFormatted: formatDate(data.dateFormatted, 'YYYY-MM-DD[T]HH:mm'),
                editors: data.editors.map((item) => item.value),
                title: data.title,
                project: ctx?.project?.id || Number(projectId),
                ...(ctx?.projectProposalType.id && { projectProposalType: ctx?.projectProposalType.id })
            };

            const meetingRecord = await createOrUpdateMeetingRecord(payload);

            const meeting = {
                dateFormatted: formatDate(meetingRecord.date, 'YYYY-MM-DD[T]HH:mm'),
                editors: meetingRecord.editors.map((item) => ({
                    value: (item as any).id,
                    label: (item as any).name
                })),
                title: meetingRecord.title,
                project: meetingRecord.project.id,
                projectProposalType: meetingRecord.projectProposalType?.id
            };

            setContent({ meeting }, true);

            if (!meetingRecordId) {
                const url = Boolean(ctx) ? `../${meetingRecord.id}/editar` : `/app/contratos/${ctx?.project?.id || Number(projectId)}/atas-de-reuniao/${meetingRecord.id}/editar`;

                navigate(url, { replace: true });
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    return <Details meeting={state.content.meeting} projectProposalTypeId={ctx?.projectProposalType.id} isBuilding={isLoading} isSubmitting={isSubmitting} people={people} onSubmit={handleSubmit} />;
};

export default Meeting;
