import { AssetsStatus } from 'types/models/assets';
import { ClassificationType } from 'types/models/classification';
import dictionary from 'utils/dictionary';

type ConfigGraphqlLiteral = { [ClassificationType: number | string]: { title?: string; columns: { name: string; label?: string; options?: object }[]; where?: any } };

export const getAssetStatusVariant = {
    [AssetsStatus.Available]: 'success',
    [AssetsStatus.InUse]: 'info',
    [AssetsStatus.Breakdown]: 'error'
};

export const configsGraphql: ConfigGraphqlLiteral = {
    [ClassificationType.Asset]: {
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'system',
                options: { display: 'excluded' }
            },
            {
                name: 'system',
                options: { display: 'excluded' }
            }
        ],
        where: {
            type: { _eq: ClassificationType.Asset }
        }
    },
    [ClassificationType.Effective]: {
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'system',
                options: { display: 'excluded' }
            }
        ],
        where: {
            type: { _eq: ClassificationType.Effective }
        }
    },
    [ClassificationType.Solicitation]: {
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'system',
                options: { display: 'excluded' }
            }
        ],
        where: {
            type: { _eq: ClassificationType.Solicitation }
        }
    },
    [ClassificationType.WeatherCondition]: {
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'system',
                options: { display: 'excluded' }
            }
        ],
        where: {
            type: { _eq: ClassificationType.WeatherCondition }
        }
    },
    [ClassificationType.Document]: {
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'classification.name',
                label: 'Classificação Pai'
            },
            {
                name: 'system',
                options: { display: 'excluded' }
            }
        ],
        where: {
            type: { _eq: ClassificationType.Document }
        }
    },
    [ClassificationType.PersonDocument]: {
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'system',
                options: { display: 'excluded' }
            }
        ],
        where: {
            type: { _eq: ClassificationType.PersonDocument }
        }
    },
    [ClassificationType.ProjectDocument]: {
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'system',
                options: { display: 'excluded' }
            }
        ],
        where: {
            type: { _eq: ClassificationType.ProjectDocument }
        }
    },
    [ClassificationType.ProviderServices]: {
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'classification.name',
                label: 'Classificação Pai'
            },
            {
                name: 'system',
                options: { display: 'excluded' }
            }
        ],
        where: {
            type: { _eq: ClassificationType.ProviderServices }
        }
    },
    [ClassificationType.Scope]: {
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'classification.name',
                label: 'Classificação Pai'
            },
            {
                name: 'project_service.title',
                label: 'Serviço'
            },
            {
                name: 'system',
                options: { display: 'excluded' }
            }
        ],
        where: {
            type: { _eq: ClassificationType.Scope }
        }
    },
    [ClassificationType.FinancialClassification]: {
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'system',
                options: { display: 'excluded' }
            }
        ],
        where: {
            type: { _eq: ClassificationType.FinancialClassification }
        }
    },
    [ClassificationType.Departament]: {
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'system',
                options: { display: 'excluded' }
            }
        ],
        where: {
            type: { _eq: ClassificationType.Departament }
        }
    },
    [ClassificationType.TransactionDocument]: {
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'system',
                options: { display: 'excluded' }
            }
        ],
        where: {
            type: { _eq: ClassificationType.TransactionDocument }
        }
    },
    jobPosition: {
        columns: [
            {
                name: 'title',
                label: dictionary.content.graphql['title']
            },
            {
                name: 'classification.name',
                label: dictionary.content.graphql['department']
            }
        ],
        where: {}
    },
    typeBusiness: {
        columns: [
            {
                name: 'title',
                label: dictionary.content.graphql['title']
            }
        ],
        where: {}
    }
};
