import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import Input from '../input';
import ErrorMessage from 'components/error/message';
import classNames from 'classnames';

type CurrencyInputProps = {
    placeholder: string;
    label?: string;
    error?: string;
    inpuWrapperClasses?: string;
    leftClasses?: string;
    parentClassName?: string;
    left?: JSX.Element;
    right?: JSX.Element;
    rightClasses?: string;
} & NumericFormatProps;

const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(({ error, left, leftClasses, right, rightClasses, ...props }, ref) => {
    const leftClassNames = classNames('bg-base-200 px-4', leftClasses);
    const rightElementClasses = classNames('flex items-center', rightClasses);

    return (
        <>
            <NumericFormat
                {...props}
                getInputRef={ref}
                customInput={Input}
                leftClasses={leftClassNames}
                rightClasses={rightElementClasses}
                left={left}
                right={right}
                thousandSeparator="."
                decimalSeparator=","
            />
            <ErrorMessage visible={Boolean(error)}>{error}</ErrorMessage>
        </>
    );
});

export default CurrencyInput;
