// Dependencies
import isEmpty from 'lodash/isEmpty';
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { WhereClause } from 'types/graphql';
import states, { getStateKey } from './graphql';
import { removeFalsyValues } from 'utils/object';
import { State } from 'types/models/address';

const useGetState = (key: keyof State, variables?: WhereClause<Partial<State>>) => {
    const getCity = () => apiGraphQl<any>(states.getState, variables).then((data) => data.state[0]);

    const isInvalid = isEmpty(removeFalsyValues(variables?.where[key]));

    return useQuery(getStateKey(variables), getCity, { enabled: !isInvalid });
};

export default useGetState;
