import { RouteObject } from 'react-router-dom';
import TableCell from '@mui/material/TableCell/TableCell';

import ListPage from 'pages/private/crud/list';
import Provider from 'pages/private/providers';
import ProvidersCreateOrUpdatePage from 'pages/private/providers/create-or-update';
import ProviderTabDetails from 'pages/private/providers/details/tabs/details';

import dictionary from 'utils/dictionary';
import { CrudPageProps } from 'types/graphql';
import { UseTabRouteChildren } from 'hooks/tab/use-tab-route';
import ProviderTabProjects from 'pages/private/providers/details/tabs/projects';
import ProviderTabContacts from 'pages/private/providers/details/tabs/contacts';
import ProviderContactsForm from 'pages/private/providers/details/tabs/contacts/form';
import Badge from 'components/core/badge';
import ProviderServicesTypesPage from 'pages/private/providers/details/tabs/services-types';
import ProviderServicesTypesForm from 'pages/private/providers/details/tabs/services-types/form';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import ContactDetails from 'components/contacts/details';
import { getLabelNameByPersonContractType } from 'components/company/utils';

const [list, create, edit]: CrudPageProps[] = [
    {
        graphql: {
            table: 'provider',
            excludedSearchColumnsName: ['type', 'type_business.title', 'partner'],
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'title',
                    label: dictionary.content.graphql['title']
                },
                {
                    name: 'juridic_name',
                    label: dictionary.content.graphql['juridic_name'],
                    options: {
                        display: 'excluded'
                    }
                },
                {
                    name: 'juridic_fantasy_name',
                    label: dictionary.content.graphql['juridic_fantasy_name'],
                    options: {
                        display: 'excluded'
                    }
                },
                {
                    name: 'juridic_document',
                    label: dictionary.content.graphql['juridic_document'],
                    options: {
                        display: 'excluded'
                    }
                },
                {
                    name: 'personal_document',
                    label: 'Documento Pessoal',
                    options: {
                        display: 'excluded'
                    }
                },
                {
                    name: 'type',
                    label: 'Tipo',
                    options: {
                        customBodyRender(_, tableMeta) {
                            const data = tableMeta?.rowData?.at(-1)?.props?.item;
                            const type = data?.type;

                            const optionByTypeLabel = getLabelNameByPersonContractType(type);

                            if (!optionByTypeLabel) {
                                return '-';
                            }

                            return <Badge variant="info">{optionByTypeLabel}</Badge>;
                        }
                    }
                },
                {
                    name: '',
                    label: 'Documento',
                    options: {
                        customBodyRender(_, tableMeta) {
                            const data = tableMeta?.rowData.at(-1)?.props?.item;

                            const document = data?.personal_document || data?.juridic_document || '-';

                            return document;
                        }
                    }
                },
                {
                    name: 'type_business.title',
                    label: 'Tipo de Negócio',
                    options: {
                        customBodyRender(value = '-') {
                            return value;
                        }
                    }
                },
                {
                    name: 'partner',
                    label: dictionary.content.graphql['partner'],
                    options: {
                        customBodyRender: (value) => <Badge variant={value ? 'success' : 'error'}>{value ? 'Sim' : 'Não'}</Badge>
                    }
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ]
        },
        title: 'Fornecedores',
        api: {
            endpoint: '/providers',
            useApiTodelete: true
        }
    },
    {
        title: 'Novo fornecedor'
    },
    {
        title: 'Editar fornecedor'
    }
];

const providerChildrens: UseTabRouteChildren[] = [
    {
        label: 'Detalhes',
        index: true,
        element: <Page Component={ProviderTabDetails} code="fornecedores" permissions={[Rule.Show]} />
    },
    {
        label: 'Contatos',
        path: 'contatos',
        element: <Page Component={ProviderTabContacts} code="fornecedores-contatos" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={ProviderContactsForm} code="fornecedores-contatos" permissions={[Rule.Create]} withModal={true} />
            },
            {
                path: 'editar/:contactId',
                element: <Page Component={ProviderContactsForm} code="fornecedores-contatos" permissions={[Rule.Update]} withModal={true} />
            },
            {
                path: ':contactId',
                element: <Page Component={ContactDetails} code="fornecedores-contatos" permissions={[Rule.Show]} withModal={true} />
            }
        ]
    },
    {
        label: 'Projetos',
        path: 'projetos',
        element: <Page Component={ProviderTabProjects} code="fornecedores-projetos" permissions={[Rule.List, Rule.Show]} />
    },
    {
        label: 'Tipos de serviços',
        path: 'tipos-servicos',
        element: <Page Component={ProviderServicesTypesPage} code="fornecedores-servicos" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={ProviderServicesTypesForm} code="fornecedores-servicos" permissions={[Rule.Create]} withModal={true} />
            },
            {
                path: 'editar/:serviceTypeId',
                element: <Page Component={ProviderServicesTypesForm} code="fornecedores-servicos" permissions={[Rule.Update]} withModal={true} />
            }
        ]
    }
];

const providers: RouteObject[] = [
    {
        path: 'fornecedores',
        element: <Page Component={ListPage} code="fornecedores" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={ProvidersCreateOrUpdatePage} code="fornecedores" permissions={[Rule.Create]} {...create} withModal={true} />
            },
            {
                path: 'editar/:providerId',
                element: <Page Component={ProvidersCreateOrUpdatePage} code="fornecedores" permissions={[Rule.Create]} {...edit} withModal={true} />
            }
        ]
    },
    {
        path: 'fornecedores/:providerId',
        element: <Page Component={Provider} code="fornecedores" permissions={[Rule.List, Rule.Show]} />,
        children: providerChildrens
    }
];

export default providers;
