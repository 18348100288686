import React, { useMemo } from 'react';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables';
import DatatableFooter from './components/footer';
import DatatableActions from './components/actions';
import Checkbox from 'components/core/form/checkbox';
import { Modify } from 'types/general';
import dictionary from 'utils/dictionary';
import { DatatableColumn, ItemsPerPage } from 'types/graphql';
import DataTableSearchInput from './components/search-input';
import classNames from 'classnames';
import { Rule } from 'types/permissions';
import { MenuItem } from 'components/menu';
import TableCell from '@mui/material/TableCell/TableCell';
import Text from 'components/core/text';

export type ModifiedDataTableOptions = Modify<MUIDataTableOptions, { rowsPerPage?: ItemsPerPage }>;

type ModifiedDataTableProps = Modify<
    MUIDataTableProps,
    {
        title?: string;
        columns: DatatableColumn[];
        options?: ModifiedDataTableOptions;
    }
>;

type DatatableProps = {
    footerLeft?: React.ReactNode;
    loading?: boolean;
    withItemsPerPage?: boolean;
    onDelete?: (id?: number, slug?: string) => void;
    withBorder?: boolean;
    hideFooter?: boolean;
    data?: any;
    actions?: Rule[];
    advancedSearchComponent?: React.ReactNode;
    customActions?: MenuItem[] | any;
    canShowDeleteButton?: (item: any) => boolean;
} & ModifiedDataTableProps;

const Datatable = ({
    actions,
    columns,
    data,
    footerLeft,
    loading = false,
    options,
    withItemsPerPage = true,
    onDelete,
    withBorder = false,
    hideFooter = false,
    advancedSearchComponent,
    customActions,
    canShowDeleteButton,
    ...props
}: DatatableProps) => {
    const customRoutePath = useMemo(() => columns.find((item) => item.name === 'actions')?.customRoutePath, [columns]);

    const items = useMemo(() => {
        return data.map((item) => ({
            ...item,
            ...(!!actions?.length && {
                actions: (
                    <DatatableActions
                        customActions={customActions}
                        canShowDeleteButton={canShowDeleteButton}
                        customRoutePath={customRoutePath}
                        item={item}
                        key={(item as any).id}
                        actions={actions}
                        onDelete={onDelete}
                    />
                )
            })
        }));
    }, [actions, data, customRoutePath, customActions, canShowDeleteButton, onDelete]);

    const { searchPlaceholder } = options || {};

    const className = classNames('rounded-[20px]', withBorder && 'border-base-300 border');

    const formattedColumns = columns.map((item) => ({
        ...item,
        options: {
            ...item.options,
            customHeadRender: [true, undefined].includes(item.options?.sort)
                ? undefined
                : (columnMeta) => {
                      return (
                          <TableCell>
                              <Text variant="body.medium.2xs" className="text-base-500 uppercase">
                                  {columnMeta.label}
                              </Text>
                          </TableCell>
                      );
                  }
        }
    }));

    return (
        <div className={className}>
            <MUIDataTable
                {...props}
                columns={formattedColumns}
                data={items}
                options={{
                    textLabels: dictionary.content.datatable,
                    responsive: 'standard',
                    ...options,
                    customSearchRender: (searchText, handleSearch) => (
                        <DataTableSearchInput
                            advancedSearchComponent={advancedSearchComponent}
                            searchPlaceholder={searchPlaceholder || 'Buscar'}
                            searchText={Boolean(advancedSearchComponent) ? searchText || '' : options?.searchText || ''}
                            handleSearch={handleSearch}
                        />
                    ),
                    searchProps: {
                        // @ts-ignore
                        autoFocus: !options?.searchAlwaysOpen
                    },
                    fixedHeader: true
                }}
                components={{
                    Checkbox,
                    TableFooter: (footerProps) => (hideFooter ? null : <DatatableFooter loading={loading} footerLeft={footerLeft} withItemsPerPage={withItemsPerPage} {...footerProps} />)
                }}
            />
        </div>
    );
};

export default Datatable;
