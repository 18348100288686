import React from 'react';
import Avatar from '@mui/material/Avatar/Avatar';
import CardActions from '@mui/material/CardActions/CardActions';
import CardContent from '@mui/material/CardContent/CardContent';
import CardHeader from '@mui/material/CardHeader/CardHeader';
import Chip from '@mui/material/Chip/Chip';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import Card from 'components/core/card';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import useTheme from '@mui/material/styles/useTheme';
import logoJP from 'assets/images/logo-jp.png';

import { pluralize } from 'utils/string';
import { CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import { Operational } from 'types/models/operationals';
import { ProjectTypeStatus } from 'types/models/project';

const OperationalCard = ({ project, progress, director, manager, daysRemain, id, late, projectTypeStatus }: Operational) => {
    const { palette } = useTheme();

    return (
        <Card>
            <CardActionArea component={Link} to={`${id}`} className="p-4" classes={{ focusHighlight: 'hidden' }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: '#FFF', borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                            <img src={project?.customer?.image?.url || logoJP} />
                        </Avatar>
                    }
                    classes={{ root: 'px-2' }}
                    title={project?.title}
                    subheader={
                        <div>
                            {Boolean(project?.code) && (
                                <Text as="span" className="text-black opacity-60 ml-0">
                                    #{project.code} -
                                </Text>
                            )}{' '}
                            {project?.customer?.title}
                        </div>
                    }
                    titleTypographyProps={{ fontSize: 18, color: palette.heading, fontWeight: 500 }}
                />
                <CardContent classes={{ root: 'px-2' }}>
                    <div>
                        <div className="flex justify-between mb-1">
                            <Text as="p" variant="body.regular.sm" className="text-base-500">
                                Progresso
                            </Text>
                            <Text as="p" variant="body.regular.sm" className="text-base-500">
                                {progress}%
                            </Text>
                        </div>
                        <LinearProgress color="success" value={progress || 0} variant="determinate" classes={{ root: 'h-[6px] rounded-[3px] bg-base-300', bar: 'rounded-[3px]' }} />
                    </div>
                </CardContent>
                <CardActions>
                    <div className="grid grid-cols-1 sm:gap-0 gap-2 sm:grid-cols-2 justify-between w-[100%]">
                        <div>
                            {daysRemain && projectTypeStatus !== ProjectTypeStatus['Done'] && (
                                <Chip
                                    avatar={<Icon name={`ico-${late ? 'emoji-shocked' : 'clock'}`} width={16} height={16} className="!ml-0 !mr-1 text-heading" />}
                                    label={`${late ? 'Atrasado' : 'Restam'} ${pluralize(daysRemain || 0, 'dias', 'dia')}`}
                                    classes={{ root: `h-[unset] ${late ? 'bg-[#febcb94d]' : 'bg-base-200'}  px-2 pr-3 py-1`, label: `p-0 ${late ? 'text-[#be5b56]' : 'text-heading'} text-sm` }}
                                />
                            )}
                            {projectTypeStatus === ProjectTypeStatus['Done'] && (
                                <Chip
                                    avatar={<Icon name="ico-emoji-nerd" width={16} height={16} className="!ml-0 !mr-1 text-heading" />}
                                    label="Finalizado"
                                    classes={{ root: 'h-[unset] bg-[#a4e4b64d] px-2 pr-3 py-1', label: 'p-0 text-[#379752] text-sm' }}
                                />
                            )}
                        </div>

                        <div className="flex items-center justify-self-start sm:justify-self-end">
                            {Boolean(director) && (
                                <Avatar src={director.profileImage as string} sx={{ width: 30, height: 30, marginRight: 1 }}>
                                    {director.name.charAt(0)}
                                </Avatar>
                            )}
                            {Boolean(manager) && (
                                <Avatar src={(manager as any)?.profileImage as string} sx={{ width: 30, height: 30, marginRight: 1 }}>
                                    {manager?.name.charAt(0)}
                                </Avatar>
                            )}
                        </div>
                    </div>
                </CardActions>
            </CardActionArea>
        </Card>
    );
};

export default OperationalCard;
