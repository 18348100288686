// Dependencies
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { getAllKey } from '../crud/graphql';
import useToast from 'hooks/toast/use-toast';
import queries from './graphql';
import { ApiPayload, GraphqlPaginationVariables, GraphqlQueryPayload } from 'types/graphql';
import api from 'services/api';

type ArgsType<T> = {
    queryConfig?: GraphqlQueryPayload;
    variables?: GraphqlPaginationVariables<T>;
    apiPayload?: ApiPayload;
    onSuccess?(): void;
};

type Payload =
    | {
          id?: number;
          slug?: string;
      }
    | undefined;

const useDelete = <T>(props: ArgsType<T>) => {
    const { queryConfig, variables, apiPayload, onSuccess } = props || {};

    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const deleteItem = async (payload: Payload) => {
        return apiPayload?.useApiTodelete
            ? api.delete(apiPayload?.endpoint + `/${payload?.id}`)
            : apiGraphQl<any>(queries.delete(queryConfig?.table || '', !!payload?.slug), { id: payload?.id, slug: payload?.slug });
    };

    return useMutation(deleteItem, {
        onSuccess: () => {
            if (queryConfig) {
                queryClient.invalidateQueries(getAllKey(queryConfig.table, variables));
            }

            showToast('Registro apagado com sucesso!', 'success');

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: () => {
            showToast('Ocorreu um erro ao apagar o registro. Tente novamente.', 'danger');
        }
    });
};

export default useDelete;
