import { memo, useEffect, useMemo, useRef, useState } from 'react';

// Dependencies
import { UseFormReturn } from 'react-hook-form';

// Components
import Form from './form';

// Helpers
import useGetCity from 'services/queries/cities/use-get-city';
import useGetState from 'services/queries/states/use-get-state';
import useGetStateOptions from 'services/queries/states/use-get-state-options';
import useGetCitiesOptions from 'services/queries/cities/use-get-cities-options';

type AddressFormProps = {
    className?: string;
} & UseFormReturn<any, any>;

const AddressForm = (props: AddressFormProps) => {
    const [stateId, setStateId] = useState<number>();
    const [cityName, setCityName] = useState<string>();
    const [stateInitial, setStateInitial] = useState<string>();

    const filterCity = useMemo(
        () => ({
            where: {
                name: { _eq: cityName }
            }
        }),
        [cityName]
    );

    const filterState = useMemo(
        () => ({
            where: {
                initials: { _eq: stateInitial }
            }
        }),
        [stateInitial]
    );

    const wasInitialStateIdSetted = useRef(false);

    const watchStateId = props?.watch('address.state_id');

    useEffect(() => {
        if (!wasInitialStateIdSetted.current && !!watchStateId) {
            wasInitialStateIdSetted.current = true;

            setStateId(watchStateId);
        }
    }, [watchStateId]);

    const { data: states } = useGetStateOptions();
    const { data: cities } = useGetCitiesOptions(stateId);
    const { data: city } = useGetCity('name', filterCity);
    const { data: state } = useGetState('initials', filterState);

    return <Form {...props} city={city?.id} state={state?.id} cities={cities || []} states={states || []} onGetCity={setCityName} onGetState={setStateInitial} onSelectState={setStateId} />;
};

export default memo(AddressForm);
