import { array, boolean, date, lazy, mixed, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';
import { ProjectStatusEnum, ProjectTypeEnum } from 'types/models/project';

const projectBaseSchema = (duration: string, proposalId: string, projectTypeId: number, percentageAverage?: (value: any, context: any) => boolean, projectTypeSlug?: string) => {
    return object({
        investment: object({
            floatValue: number(),
            formattedValue: string(),
            value: string()
        }).required(dictionary.validation.required),
        investmentType: string().required(dictionary.validation.required),
        observations: string().optional(),
        startDate: !!duration ? date().nullable(true) : date().required(dictionary.validation.required),
        endDate: date()
            .when('startDate', (start, schema) => {
                return start ? schema.min(start, dictionary.validation.date.min).required(dictionary.validation.required) : undefined;
            })
            .nullable(true),
        duration: string()
            .when(['startDate', 'endDate'], {
                is: (start, end) => !start || !end,
                then: string().required(dictionary.validation.required),
                otherwise: string().nullable()
            })
            .nullable(true),
        director: number().required(dictionary.validation.required),
        proposal: number().required(dictionary.validation.required).default(Number(proposalId)).nullable(true),
        budget: object({
            floatValue: number(),
            formatedValue: string(),
            value: string()
        }).required(dictionary.validation.required),
        deadlineType: number().nullable(true),
        ...(projectTypeSlug === ProjectStatusEnum.PreTap && {
            ...(projectTypeId === ProjectTypeEnum.Projects && {
                services: array(number()).required(dictionary.validation.required)
            }),
            ...(projectTypeId === ProjectTypeEnum.Projects && {
                methodology: number().required(dictionary.validation.required)
            }),
            hasPhases: boolean().required(dictionary.validation.required).default(false),
            phases: lazy(() => {
                const schema = array(mixed()).when('hasPhases', {
                    is: true,
                    then: array(mixed()).required(dictionary.validation.required),
                    otherwise: (schema) => schema.nullable(true)
                });

                if (projectTypeId !== ProjectTypeEnum.Projects && percentageAverage) {
                    return schema.when('hasPhases', {
                        is: true,
                        then: (schema) => schema.test('percentageAverage', 'A soma dos percentuais não corresponde à 100%', percentageAverage),
                        otherwise: (schema) => schema.nullable(true).notRequired()
                    });
                }

                return schema;
            })
        })
    });
};

export default projectBaseSchema;
