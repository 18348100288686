import { useTheme } from '@mui/material';
import classNames from 'classnames';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import { Option } from 'types/general';

type SimpleCardProps = {
    className?: string;
    withIcon?: boolean;
    variant?: 'list' | 'details';
} & Partial<Option<string | number>>;

const SimpleCard = ({ variant = 'details', label, value, className, withIcon = false }: SimpleCardProps) => {
    const { palette } = useTheme();

    const containerClasses = classNames(
        'py-4 px-6 flex items-center justify-between flex-wrap',
        variant === 'details' ? 'border-r border-r-base-300 border-b border-b-base-300 last:border-r-0' : 'bg-base-100 rounded-[14px]',
        className
    );

    return (
        <div className={containerClasses}>
            <div>
                <Text as="p" className="text-base-500 font-normal text-sm mb-2">
                    {label}
                </Text>
                <Text as="strong" className="font-medium text-xl text-heading flex-1 mb-3">
                    {value}
                </Text>
            </div>
            {withIcon && (
                <div className="flex justify-center items-center w-[45px] h-[45px] rounded-xl bg-secondary-100">
                    <Icon name="ico-dollar" width={25} height={25} color={palette.secondary[500]} />
                </div>
            )}
        </div>
    );
};

export default SimpleCard;
