import { useCallback, useEffect, useMemo, useState } from 'react';
import Avatar from '@mui/material/Avatar/Avatar';
import useTheme from '@mui/material/styles/useTheme';
import { CardHeader } from '@mui/material';
import Text from 'components/core/text';
import Activities from 'pages/private/projects/view/components/activities';
import { ActivityItemProps } from 'pages/private/projects/view/components/activities/item';
import { Project, ProjectStatusEnum, ProjectTypePersonResource, ProjectTypeStatus } from 'types/models/project';
import { useNavigate } from 'react-router-dom';
import { Timeline, TimelineStatusCreation } from 'types/models/timeline';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import { Person } from 'types/models/person';
import { generateFileUrl } from 'utils/file';
import dayjs from 'dayjs';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import useStartConstruction from 'services/queries/operationals/use-start-construction';
import useConfig from 'store/config/use-config';
import Icon from 'components/core/icon';
import useProjectTypeSlug from '../hooks/use-project-type-slug';
import dictionary from 'utils/dictionary';

import classNames from 'classnames';
import useResponsive from 'hooks/responsive/use-responsive';

type ConstructionSidebarProps = {
    onAddResource: () => void;
    project?: Project;
    resources?: Partial<ProjectTypePersonResource>[];
    checklist?: Timeline;
    director?: Partial<Person>;
    projectTypeStatus?: ProjectTypeStatus;
    projectProposalTypeId?: number;
    taskPlanningId?: number;
    taskPlanningStatusCreation?: TimelineStatusCreation;
};

const startConstructionTxt = (date?: string) => {
    const startDate = dayjs(date);
    const days = startDate.diff(dayjs(), 'days');
    const str = days > 0 ? `Faltam ${days} dia(s) para o início da obra.` : `A obra está atrasada há ${Math.abs(days)} dia(s)`;

    return str;
};

const ConstructionSidebar = ({
    taskPlanningStatusCreation,
    director,
    project,
    resources = [],
    checklist,
    projectTypeStatus,
    projectProposalTypeId,
    taskPlanningId,
    onAddResource
}: ConstructionSidebarProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { config, setConfig } = useConfig();
    const { isManagement } = useProjectTypeSlug();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const [startConstructionModalOpen, setStartConstructionModalOpen] = useState(false);

    const { mutateAsync: startConstruction, isLoading, isSuccess } = useStartConstruction(projectProposalTypeId);

    useEffect(() => {
        if (isSuccess && startConstructionModalOpen) {
            setStartConstructionModalOpen(false);
        }
    }, [isSuccess, startConstructionModalOpen]);

    const handleGoTo = useCallback(
        (path: string) => () => {
            if (isSmallerThenTabletLandscape) {
                setConfig('operational', { ...config.operational, isSidebarOpen: false });
            }
            navigate(path);
        },
        [navigate, config.operational, isSmallerThenTabletLandscape, setConfig]
    );

    const tapReleased = useMemo(() => project?.project_status?.slug !== ProjectStatusEnum.PreTap, [project?.project_status?.slug]);

    const handleCreateCiso = useCallback(() => {
        if (!tapReleased) {
            return alert('TAP ainda não liberado');
        }

        handleGoTo('cisos/novo')();
    }, [tapReleased, handleGoTo]);

    const ciso = project?.project_management_informations?.[0];

    const handleStartConstruction = async () => {
        try {
            if (!projectProposalTypeId) {
                return;
            }

            await startConstruction({ id: Number(projectProposalTypeId) });
        } catch (error) {
            console.log('handleStartConstruction', error);
        }
    };

    const activities = useMemo<ActivityItemProps[]>(() => {
        if (!isManagement) {
            return [
                {
                    title: 'Planejamento de tarefas',
                    helperText: 'Deseja adicionar tarefas ao planejamento de tarefa?',
                    button: { action: handleGoTo(`planejamento-de-tarefas/${taskPlanningId}`), label: 'Adicionar tarefas' },
                    done: taskPlanningStatusCreation === TimelineStatusCreation.Finalized,
                    doneText: 'Configuração de planejamento de tarefa finalizada'
                }
            ];
        }

        const items = [
            {
                title: 'Recursos',
                helperText: 'Deseja alocar recursos para este projeto?',
                button: { action: onAddResource, label: 'Alocar recursos' },
                done: project?.project_status.slug !== ProjectStatusEnum.PreTap,
                doneText: 'Recursos alocados'
            },
            {
                title: 'CISO',
                helperText: 'Deseja criar o CISO?',
                button: { action: handleCreateCiso, label: 'Criar CISO' },
                done: Boolean(ciso),
                doneText: 'CISO criado'
            },
            {
                title: 'Checklist',
                helperText: 'Deseja criar o checklist de start de obra?',
                button: { action: handleGoTo(`checklist/novo`), label: 'Criar checklist' },
                done: Boolean(checklist?.tasks?.length),
                doneText: 'Checklist criado',
                doneActions: [
                    {
                        label: 'Ver',
                        action: handleGoTo(`checklist/${checklist?.id}`)
                    },
                    {
                        label: 'Editar',
                        action: handleGoTo(`checklist/editar`)
                    }
                ]
            },
            {
                title: 'Start de obra',
                helperText: `${startConstructionTxt(ciso?.management_start_date)}. Deseja iniciá-la?`,
                button: { action: () => setStartConstructionModalOpen(true), label: 'Iniciar obra' },
                done: projectTypeStatus === ProjectTypeStatus.Started,
                doneText: 'Obra iniciada'
            },
            {
                title: 'CIEO',
                helperText: 'Deseja criar o CIEO?',
                button: { action: () => null, label: 'Criar CIEO' }, // TODO: adicionar evento
                done: false, // TODO: adicionar validação
                doneText: 'CIEO criado'
            }
        ];

        return items;
    }, [project?.project_status.slug, ciso, taskPlanningStatusCreation, projectTypeStatus, onAddResource, handleCreateCiso, handleGoTo, checklist, taskPlanningId, isManagement]);

    const getPosition = (item: Partial<ProjectTypePersonResource>) => {
        if (Boolean(item?.provider)) {
            return 'Parceiro';
        }

        if (Boolean(item.type_management_information)) {
            return Object.values(dictionary.content.typeManagementInformations)[(item.type_management_information || 0) - 1] || '-';
        }

        return item?.person?.person_contract?.job_position?.title || '-';
    };

    return (
        <>
            <aside
                className={`relative md:transition-all ${
                    config?.operational?.isSidebarOpen ? 'bg-white z-[201] !fixed md:!relative md:top-0 left-0 md:h-auto h-[100%]  top-0 w-[100vw] md:w-[325px]' : 'h-0 w-[0px]'
                }`}>
                <button
                    type="button"
                    onClick={() => setConfig('operational', { isSidebarOpen: !config.operational.isSidebarOpen })}
                    className={classNames(
                        'z-[202] w-9 h-9 bg-base-200 rounded-br absolute right-0 md:right-[320px] top-[8px] md:top-[0px] flex items-center justify-center hover:bg-base-300 transition-colors',
                        !config?.operational?.isSidebarOpen ? '!right-0' : '!top-[0px] md:!top-[0px]'
                    )}>
                    <Icon name={config.operational.isSidebarOpen ? 'ico-chevron-right' : 'ico-chevron-left'} color={palette.heading} width={16} height={16} />
                </button>
                <div className="overflow-y-auto h-[100%] md:h-[calc(100vh-70px)]">
                    <div className="border-b-base-300 border-b p-6">
                        <Text as="h6" variant="body.medium.2xs" className="text-heading mb-6">
                            MEMBROS
                        </Text>
                        <div>
                            {Boolean(director) && (
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            src={generateFileUrl(director?.user?.file?.filename || '', director?.user?.file?.path || '')}
                                            sx={{ borderRadius: '50% !important' }}
                                            aria-label="recipe">
                                            {director?.name?.charAt(0)}
                                        </Avatar>
                                    }
                                    classes={{ root: 'p-0' }}
                                    title={director?.name}
                                    subheader="Gerente de operações"
                                    titleTypographyProps={{ color: palette.heading }}
                                    subheaderTypographyProps={{ color: palette.grey[500], fontSize: 12 }}
                                    className="mb-3"
                                />
                            )}
                            {resources?.map((item, index) => {
                                const isLastItem = resources?.length - 1 === index;

                                return (
                                    <CardHeader
                                        key={item.id}
                                        avatar={
                                            <Avatar
                                                src={generateFileUrl(item.person?.user?.file?.filename || '', item.person?.user?.file?.path || '')}
                                                sx={{ borderRadius: '50% !important' }}
                                                aria-label="recipe">
                                                {item.person?.name?.charAt(0)}
                                            </Avatar>
                                        }
                                        classes={{ root: 'p-0' }}
                                        title={Boolean(item?.provider) ? item?.provider?.title : item.person?.name}
                                        subheader={getPosition(item)}
                                        titleTypographyProps={{ color: palette.heading }}
                                        subheaderTypographyProps={{ color: palette.grey[500], fontSize: 12 }}
                                        className={isLastItem ? '' : 'mb-3'}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="border-b-base-300 border-b p-6">
                        <Text as="h6" variant="body.medium.2xs" className="text-heading mb-3">
                            ATIVIDADES
                        </Text>
                        <Activities activities={activities} />
                    </div>
                    <div className="p-6">
                        <Text as="h6" variant="body.medium.2xs" className="text-heading mb-6">
                            PROGRESSO
                        </Text>
                        {isManagement && (
                            <div className="mb-4">
                                <div className="flex justify-between mb-1">
                                    <Text as="p" variant="body.regular.sm" className="text-base-500">
                                        Checklist
                                    </Text>
                                    <Text as="p" variant="body.regular.sm" className="text-base-500">
                                        {checklist?.progressPercentage || 0}%
                                    </Text>
                                </div>
                                <LinearProgress
                                    color="primary"
                                    value={checklist?.progressPercentage || 0}
                                    variant="determinate"
                                    classes={{ root: 'h-[6px] rounded-[3px] bg-base-300', bar: 'rounded-[3px]' }}
                                />
                            </div>
                        )}
                        {project?.proposal?.proposal_types?.map((projectProposalType) => {
                            return (
                                <div className="mb-4 last:mb-0" key={projectProposalType?.id}>
                                    <div className="flex justify-between mb-1">
                                        <Text as="p" variant="body.regular.sm" className="text-base-500">
                                            {projectProposalType.project_type?.title}
                                        </Text>
                                        <Text as="p" variant="body.regular.sm" className="text-base-500">
                                            {projectProposalType.progress || 0}%
                                        </Text>
                                    </div>
                                    <LinearProgress
                                        color="primary"
                                        value={projectProposalType.progress || 0}
                                        variant="determinate"
                                        classes={{ root: 'h-[6px] rounded-[3px] bg-base-300', bar: 'rounded-[3px]' }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </aside>
            {startConstructionModalOpen && (
                <Modal contentClassnames="w-[500px]" closeOnClickOutside={false} onClose={() => setStartConstructionModalOpen(false)}>
                    <div className="p-6">
                        <Text as="h6" variant="h5" className="text-heading mb-3">
                            Iniciar obra
                        </Text>
                        <Text as="p" variant="body.regular.sm" className="text-base-700 mb-6">
                            Você tem certeza que deseja iniciar esta obra?
                        </Text>
                        <div className="flex items-center justify-end">
                            <Button disabled={isLoading} loading={isLoading} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4" onClick={handleStartConstruction}>
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={() => setStartConstructionModalOpen(false)}>
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default ConstructionSidebar;
