import { useEffect, useState } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import AddressForm from 'components/address';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';
import Editor from 'components/core/form/editor';
import Select from 'components/core/form/select';
import Files from 'pages/private/operationals/progress/files';
import theme from 'settings/theme';
import { Option } from 'types/general';

import { ProjectPayload } from 'types/models/project';
import { FileApiModel } from 'types/models/file';
import CurrencyInput from 'components/core/form/currency';
import { schema } from './utils/schema';

type ProjectCreateProps = {
    defaultValues: ProjectPayload;
    branches: Option[];
    customerContacts: Option[];
    customers: Option[];
    isBuilding: boolean;
    isSubmitting: boolean;
    projectTypes: Option[];
    title: string;
    onSelectCustomer: (value: number) => void;
    onSubmit: (data: any) => void;
};

const ProjectCreateOrUpdate = ({ branches, defaultValues, customerContacts, customers, isBuilding, isSubmitting, projectTypes, onSelectCustomer, onSubmit }: ProjectCreateProps) => {
    const navigate = useNavigate();
    const { projectId } = useParams();

    const filesHook = useState<Array<Partial<File & FileApiModel>>>([]);

    const methods = useForm<ProjectPayload>({
        mode: 'onSubmit',
        shouldUnregister: true,
        resolver: yupResolver(schema(customerContacts)),
        defaultValues
    });

    useEffect(() => {
        if (defaultValues) {
            methods.reset(defaultValues);
        }
    }, [defaultValues, methods]);

    const { control, formState, handleSubmit, watch } = methods;

    const handleClose = () => navigate(-1);

    const submit = (data: ProjectPayload) => {
        const [files] = filesHook;

        if (!(data as any)?.address?.city_id) {
            delete data?.address;
        }

        const payload = {
            ...data,
            footage: data.footage.floatValue,
            ...(Boolean(!projectId) && { documents: files.map((item) => ({ file: item.id })) })
        };

        onSubmit(payload);
    };

    const footage = watch('footage');

    return (
        <Modal contentClassnames="w-[960px]" onClose={handleClose}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="py-5 px-7" onSubmit={handleSubmit(submit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5 text-xl">
                            {Boolean(projectId) ? 'Atualizar projeto' : 'Novo projeto'}
                        </Text>
                        <Text as="h4" variant="h5" className="text-heading mb-5">
                            Dados do cliente
                        </Text>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                            <Controller
                                name="code"
                                control={control}
                                render={({ field }) => <Input {...field} label="Código da proposta" value={field.value} error={formState.errors.code?.message} />}
                            />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-8">
                            <Controller name="title" control={control} render={({ field }) => <Input {...field} label="Título" error={formState.errors.title?.message} />} />
                            <Controller
                                name="customer"
                                control={control}
                                render={({ field }) => {
                                    const value = customers.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={customers}
                                            label="Cliente"
                                            placeholder="Selecione uma opção"
                                            error={formState.errors.customer?.message}
                                            onChange={(option: any) => {
                                                onSelectCustomer(option.value);
                                                field.onChange(option.value);
                                            }}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="branch"
                                control={control}
                                render={({ field }) => {
                                    const value = branches.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={branches}
                                            label="Unidade"
                                            placeholder="Selecione uma opção"
                                            error={formState.errors.branch?.message}
                                            onChange={(option: any) => field.onChange(option.value)}
                                        />
                                    );
                                }}
                            />
                            {Boolean(customerContacts.length) && (
                                <Controller
                                    name="customerContact"
                                    control={control}
                                    render={({ field }) => {
                                        const value = customerContacts.find((item) => item.value === field.value);

                                        return (
                                            <Select
                                                {...field}
                                                value={value}
                                                options={customerContacts}
                                                label="Contato"
                                                placeholder="Selecione uma opção"
                                                error={formState.errors.branch?.message}
                                                onChange={(option: any) => field.onChange(option.value)}
                                            />
                                        );
                                    }}
                                />
                            )}
                        </div>
                        <Text as="h5" variant="h6" className="text-heading mb-5">
                            Local do contrato
                        </Text>
                        <AddressForm {...methods} />
                        <Text as="h4" variant="h5" className="text-heading mb-5">
                            Detalhes do contrato
                        </Text>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
                            <Controller
                                name="types"
                                control={control}
                                render={({ field }) => {
                                    const value = projectTypes.filter((item) => field.value.find((value) => value === item.value));

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            isMulti={true}
                                            options={projectTypes}
                                            label="Tipo"
                                            placeholder="Selecione uma opção"
                                            error={(formState as any).errors.types?.message}
                                            onChange={(option: any) => {
                                                const ids = option.map((item) => item.value);

                                                field.onChange(ids);
                                            }}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="footage"
                                control={control}
                                render={() => (
                                    <CurrencyInput
                                        label="Área contratada"
                                        placeholder="Ex: 1000"
                                        value={footage?.floatValue}
                                        onValueChange={(values) => methods.setValue('footage', values)}
                                        error={(formState as any).errors.footage?.message}
                                        right={
                                            <Text as="span" variant="body.medium.sm" className="text-heading">
                                                m<sup>2</sup>
                                            </Text>
                                        }
                                        rightClasses="bg-base-200 px-4"
                                    />
                                )}
                            />
                        </div>
                        {Boolean(!projectId) && <Files hook={filesHook} label="Arquivos" />}
                        <div className="mb-8">
                            <Controller name="notes" control={control} render={({ field }) => <Editor {...field} label="Resumo" />} />
                        </div>
                        <div className="flex items-center">
                            <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default ProjectCreateOrUpdate;
