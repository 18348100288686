import React, { useCallback, useContext, useMemo, useState } from 'react';

// Dependencies
import Grid from '@mui/material/Grid/Grid';
import { Outlet, useNavigate } from 'react-router-dom';

// Components
import BID from './components/type/bid';
import Card from 'components/core/card';
import ReleaseTap from './components/tap';
import Spinner from 'components/core/spinner';
import PageHeader from 'components/page/header';
import Activities from './components/activities';
import ProjectDetails from './components/details';
import ProjectProposal from './components/proposal';
import Management from './components/type/management';
import MeetingRecords from './components/meeting-records';

// Helpers
import { ProjectContext } from 'contexts/project';
import { ActivityItemProps } from './components/activities/item';
import { ProjectTypeAccordion, ProjectTypeProps } from './components/type/types';
import { Project as ProjectModel, ProjectStatusEnum, ProjectType, ProjectTypeSlug } from 'types/models/project';
import ProjectDocuments from './components/documents';
import ContractDetailsActions from './components/actions/actions';
import ComplementaryProject from './components/type/complementary-project';
import ArchitecturalProject from './components/type/project';

type ProjectProps = {
    isLoadingProjectProposalTypes: boolean;
    project: Partial<ProjectModel>;
    projectProposalTypes?: Pick<ProjectType, 'id' | 'title' | 'project_proposal_types'>[];
};

const releasedTapsArray = [ProjectStatusEnum.Tap, ProjectStatusEnum.Partial, ProjectStatusEnum.Stopped, ProjectStatusEnum.Finalized];

const Project = ({ isLoadingProjectProposalTypes, project, projectProposalTypes }: ProjectProps) => {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(ProjectContext);

    const [activeAccordion, setActiveAccordion] = useState<ProjectTypeProps['activeAccordion']>(ProjectTypeAccordion.Management);

    const handleToggleAccordion = (panel: ProjectTypeProps['activeAccordion'], expanded: boolean) => setActiveAccordion(expanded ? panel : ProjectTypeAccordion.ClosedAccordion);

    const renderType = (projectType: Partial<ProjectType>) => {
        const components = new Map<ProjectTypeSlug, (props: ProjectTypeProps) => JSX.Element>([
            [ProjectTypeSlug.Management, Management],
            [ProjectTypeSlug.Projects, ArchitecturalProject],
            [ProjectTypeSlug.Bid, BID],
            [ProjectTypeSlug.ComplementaryProjects, ComplementaryProject]
        ]);

        const Component = components.get(projectType.slug!);

        if (!Component) {
            return null;
        }

        const handleAddResources = (proposalTypeId?: number) => navigate(`tipo/${proposalTypeId}/recursos`);

        const projectTypeFinded = project.proposal?.proposal_types?.find((item) => item.project_type?.slug === projectType.slug);

        return (
            <Component
                activeAccordion={activeAccordion}
                key={projectType.id}
                projectProposalId={project.proposal?.id || 0}
                projectProposalTypeId={projectTypeFinded?.id}
                projectTypeId={projectType.id || 0}
                managementInformations={project.project_management_informations}
                onAddResource={handleAddResources}
                onToggleAccordion={handleToggleAccordion}
            />
        );
    };

    const types = useMemo(() => {
        return project.proposal?.proposal_types?.map((item) => ({
            project_type_id: item.project_type?.id,
            project_proposal_id: project.proposal?.id || 0,
            project_type_slug: item?.project_type?.slug
        }));
    }, [project.proposal]);

    const handleGoTo = useCallback((path: string) => () => navigate(path), [navigate]);

    const handleReleaseTap = useCallback(() => {
        const isReadyToRelease = projectProposalTypes?.every((item) => Boolean(item.project_proposal_types.length)) && project.project_status?.slug === ProjectStatusEnum.PreTap;
        const management = project.proposal?.proposal_types?.find((item) => item.project_type?.slug === ProjectTypeSlug.Management);

        if (!!management && !management.resources_aggregate?.aggregate.count && project.project_status?.slug === ProjectStatusEnum.PreTap) {
            return alert('Para liberar o TAP é preciso adicionar a estimativa de recursos à proposta de Gerenciamento');
        }

        if (!isReadyToRelease) {
            return alert('O TAP só pode ser liberado após o preenchimento de todas as propostas');
        }

        dispatch({ type: 'SET_RELEASE_TAP_MODAL', payload: true });
    }, [project.proposal?.proposal_types, projectProposalTypes, project?.project_status?.slug, dispatch]);

    const activities = useMemo<ActivityItemProps[]>(() => {
        const items = projectProposalTypes?.map((item) => ({
            title: item.title,
            helperText: 'Deseja criar a proposta agora?',
            button: { action: handleGoTo(`proposta/${project.proposal?.id}/tipo/${item.id}`), label: 'Configurar proposta' },
            done: Boolean(item.project_proposal_types?.length),
            doneText: `Proposta de ${item.title} criada`
        }));

        const tap = {
            title: 'TAP',
            helperText: 'Deseja liberar o TAP para este contrato?',
            button: { action: handleReleaseTap, label: 'Liberar TAP' },
            done: releasedTapsArray.includes(project.project_status?.slug!) || false,
            doneText: 'TAP liberado'
        };

        return [...(items || []), tap];
    }, [project.project_status, projectProposalTypes, handleGoTo, handleReleaseTap, project.proposal?.id]);

    return (
        <>
            <PageHeader className="mb-6" title="" />
            <Grid container={true} spacing={2}>
                <Grid className="md:sticky md:top-0 md:h-full" item={true} xs={12} md={3}>
                    <Card elevation={21} className="mb-4">
                        <ProjectDetails {...project} />
                        {project.project_status?.slug === ProjectStatusEnum.PreTap && (
                            <Grid alignItems="center" justifyContent="center" display="flex" className="mb-4">
                                <ContractDetailsActions contractId={project.id} />
                            </Grid>
                        )}
                    </Card>
                    <Card elevation={21} className="mb-4">
                        {isLoadingProjectProposalTypes ? <Spinner fixed={false} size={18} parentClasses="p-3" /> : <Activities title="Atividades" activities={activities} className="px-6 py-5" />}
                    </Card>
                </Grid>
                <Grid item={true} xs={12} md={9}>
                    <ProjectProposal projectProposalId={project.proposal?.id} projectStatus={project.project_status} types={types || []} />
                    <ProjectDocuments withActions={true} />
                    <MeetingRecords projectIdProp={project.id} withTitle={true} className="mb-4" withActions={true} />
                    {project.types?.map((item) => renderType(item.type))}
                </Grid>
            </Grid>
            <Outlet />
            {state.isReleaseTapModalOpen && <ReleaseTap />}
        </>
    );
};

export default Project;
