import { useEffect, useMemo } from 'react';
import Text from 'components/core/text';
import useGetPhaseOptionsByClassification from 'services/queries/projects/use-get-phase-options-by-classification';
import { Controller, FieldArrayWithId, useFormContext } from 'react-hook-form';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import { ProjectProposalPayload } from 'types/models/project';
import Empty from 'components/empty';
import { Classification } from 'types/models/classification';

type ProjectPhaseProps = {
    classificationName: string;
    index: number;
    isComplementaryProject: boolean;
    phase: FieldArrayWithId<ProjectProposalPayload, 'phases', 'id'> & {
        project_proposal_type_phase_has_classifications?: Array<{
            classification: Partial<Classification>;
        }>;
    };
};

const ProjectPhase = ({ classificationName, isComplementaryProject, index, phase }: ProjectPhaseProps) => {
    const { control, formState, setValue, watch } = useFormContext<ProjectProposalPayload>();
    const services = watch('services');

    const { data = [] } = useGetPhaseOptionsByClassification(phase.classification!, !!phase.hasThirdPart, isComplementaryProject, services);

    const options = useMemo(() => {
        if (phase.hasThirdPart) {
            return (
                data?.map((item) => ({
                    parentName: item.name,
                    classifications: item.classifications?.map((item) => ({ value: item.id, label: item.name }))
                })) || []
            );
        }

        return data.map((item) => ({ value: item.id, label: item.name })) || [];
    }, [data, phase]);

    useEffect(() => {
        if (Boolean(options.length)) {
            if (!phase.hasThirdPart) {
                const selecteds = options
                    .map((option) => option.value)
                    .filter((item) => phase.project_proposal_type_phase_has_classifications?.find((selected) => selected.classification.id === item));

                setValue(`phases.${index}.classifications`, selecteds);
            } else {
                setValue(
                    `phases.${index}.classifications`,
                    (options as any)?.reduce((acc, curr) => [...acc, ...(curr.classifications?.map((option) => option.value) || [])], [])
                );
            }

            setValue(`phases.${index}.classification`, phase.classification);
        }
        // eslint-disable-next-line
    }, [options]);

    return (
        <div className="border border-base-300 p-4 rounded-[14px] mb-4">
            <div className="flex items-center justify-between mb-4">
                <Text as="h5" variant="h6" className="flex-1 text-heading">
                    {classificationName}
                </Text>
                <div className="flex items-center">
                    <Controller name={`phases.${index}.order`} control={control} render={({ field }) => <Input {...field} placeholder="Ordem" parentClassName="w-[100px] mr-2" />} />
                    <Controller
                        name={`phases.${index}.paymentPercentage`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                placeholder="% liberação de pagamento"
                                error={formState.errors?.phases?.[index]?.paymentPercentage?.message}
                                right={
                                    <Text as="span" variant="body.regular.xs" className="text-heading">
                                        %
                                    </Text>
                                }
                                rightClasses="bg-base-200 px-4"
                                parentClassName="w-[280px]"
                            />
                        )}
                    />
                </div>
            </div>
            <Controller
                name={`phases.${index}.classifications`}
                control={control}
                render={({ field }) => {
                    if (!phase.hasThirdPart) {
                        const value = (options as any).filter((item) => field.value?.find((value) => value === item.value));

                        return (
                            <Select
                                {...field}
                                isMulti={true}
                                value={value}
                                options={options}
                                placeholder="Selecione uma ou mais opções"
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                            />
                        );
                    }

                    if (!Boolean(options.length)) {
                        return (
                            <Select
                                {...field}
                                isDisabled={true}
                                options={options}
                                // label={option.parentName}
                                placeholder="Selecione uma ou mais opções"
                                parentClassName="mb-4"
                            />
                        );
                    }

                    return (
                        <>
                            {options.map((option, index) => {
                                if (!Boolean(option.classifications?.length)) {
                                    return (
                                        <>
                                            <Text as="label" className="pl-0 block text-base-500">
                                                {option.parentName}
                                            </Text>
                                            <Empty title="Nenhuma opção encontrada" className="mb-4" />
                                        </>
                                    );
                                }

                                const value = option.classifications.filter((item) => field.value?.find((value) => value === item.value));

                                return (
                                    <Select
                                        {...field}
                                        key={`${option.parentName}-${index}`}
                                        isMulti={true}
                                        value={value}
                                        options={option.classifications}
                                        label={option.parentName}
                                        placeholder="Selecione uma ou mais opções"
                                        parentClassName="mb-4"
                                        onChange={(value: any, newValue) => {
                                            if (newValue.removedValue) {
                                                const arrItemIds = field.value?.filter((item) => item !== (newValue as any).removedValue?.value);

                                                return field.onChange(arrItemIds);
                                            }

                                            const ids = value.map((item) => item.value);

                                            field.onChange(ids);
                                        }}
                                    />
                                );
                            })}
                        </>
                    );
                }}
            />
        </div>
    );
};

export default ProjectPhase;
