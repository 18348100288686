// Dependencies
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import useToast from 'hooks/toast/use-toast';
import { getProjectKey, getProjectProposalTypeKey } from './graphql';
import api from 'services/api';
import { ProjectProposalType } from 'types/models/project';

const useCreateOrUpdateProjectProposal = (projectId?: number, proposalId?: number) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload) => {
        const method = Boolean(proposalId) ? api.patch : api.post;
        const endpoint = Boolean(proposalId) ? `/project-types/${proposalId}` : `/project-types`;

        return method<ProjectProposalType>(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: ({ data }) => {
            queryClient.invalidateQueries(getProjectKey(projectId));
            queryClient.invalidateQueries(getProjectProposalTypeKey(data.id));

            navigate(-1);

            const isEdit = Boolean(proposalId) ? 'atualizada' : 'criada';

            showToast(`Proposta ${isEdit} com sucesso`, 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao criar a proposta', 'danger');
        }
    });
};

export default useCreateOrUpdateProjectProposal;
