import React, { useState } from 'react';
import Icon from 'components/core/icon';
import Menu, { MenuItem } from 'components/menu';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import { useNavigate } from 'react-router-dom';

type Props = {
    contractId?: number;
};

const ContractDetailsActions = ({ contractId }: Props) => {
    const { palette } = useTheme();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const navigate = useNavigate();

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const content: MenuItem[] = [
        {
            label: 'Editar contrato',
            icon: {
                color: palette.grey[500],
                name: 'ico-edit'
            },
            onClick: () => navigate(`/app/contratos/${contractId}/editar`)
        }
    ].map((item) => ({
        ...item,
        onClick: () => {
            handleClose();
            item.onClick();
        }
    }));

    return (
        <>
            <Button type="button" variant="contained" color="primary" onClick={handleOpen} endIcon={<Icon name="ico-chevron-down" width={13} color="#fff" />}>
                Ações
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={content} />
        </>
    );
};

export default ContractDetailsActions;
