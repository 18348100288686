import { memo, useEffect, useState } from 'react';

// Dependencies
import { Controller, UseFormReturn } from 'react-hook-form';

// Components
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';

// Helpers
import { Option } from 'types/general';
import { BrasilAPIAddress } from 'types/models/address';
import classNames from 'classnames';
import api from 'services/api';

type FormProps = {
    cities: Option[];
    states: Array<Option & { initials?: string }>;
    onGetCity: (name?: string) => void;
    onGetState: (name?: string) => void;
    onSelectState: (value: number) => void;
    city?: number;
    className?: string;
    state?: number;
} & UseFormReturn<any, any>;

const Form = ({ city, cities, className, state, states, onGetCity, onGetState, onSelectState, ...props }: FormProps) => {
    const { control, formState, setValue } = props;
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (state) {
            setValue('address.state_id', state);
        }
    }, [state, setValue]);

    useEffect(() => {
        if (Boolean(cities.length) && !!city) {
            setValue('address.city_id', city);
        }
    }, [cities, city, setValue]);

    const handleGetAddress = async (event: any) => {
        const zip = event.target.value;

        setValue('address.zip', zip);

        const regx = /^\d{5}-\d{3}$/;

        if (regx.test(zip)) {
            try {
                const { data } = await api.get<BrasilAPIAddress>(`https://brasilapi.com.br/api/cep/v2/${zip}`);

                setValue('address.neighbourhood', data.neighborhood);
                setValue('address.street', data.street);

                onGetState(data.state);
                onGetCity(data.city);
                setIsDisabled(true);

                const findedItem = states.find((item) => item.initials === data.state);

                if (findedItem) {
                    setValue('address.state_id', findedItem.value);
                    onSelectState(findedItem.value);
                }
            } catch (error) {
                console.log(error);
                setIsDisabled(false);

                setValue('address.neighbourhood', '');
                setValue('address.street', '');
                setValue('address.state_id', '');
                setValue('address.city_id', '');

                onGetState(undefined);
                onGetCity(undefined);
            }
        }
    };

    const handleSelectState = (onChange) => (option: any) => {
        onSelectState(option.value);

        onChange(option.value);
    };

    const containerFormClassNames = classNames('grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-8', className);

    return (
        <div className={containerFormClassNames}>
            <Controller
                name="address.zip"
                defaultValue=""
                control={control}
                render={({ field }) => <Input {...field} autoComplete="nope" type="text" mask="99999-999" label="CEP" error={formState.errors.address?.zip?.message} onBlur={handleGetAddress} />}
            />
            <Controller
                name="address.state_id"
                control={control}
                render={({ field }) => {
                    const value = states.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={states}
                            label="Estado"
                            placeholder="Selecione uma opção"
                            error={formState.errors.address?.state_id?.message}
                            onChange={handleSelectState(field.onChange)}
                            isDisabled={isDisabled}
                        />
                    );
                }}
            />
            <Controller
                name="address.city_id"
                control={control}
                render={({ field }) => {
                    const value = cities.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={cities}
                            label="Cidade"
                            placeholder="Selecione uma opção"
                            error={formState.errors.address?.city_id?.message}
                            onChange={(option: any) => field.onChange(option.value)}
                            isDisabled={isDisabled}
                        />
                    );
                }}
            />
            <Controller
                name="address.neighbourhood"
                defaultValue=""
                control={control}
                render={({ field }) => <Input {...field} disabled={isDisabled} autoComplete="nope" label="Bairro" error={formState.errors.address?.neighbourhood?.message} />}
            />
            <Controller
                name="address.street"
                defaultValue=""
                control={control}
                render={({ field }) => <Input {...field} disabled={isDisabled} autoComplete="nope" label="Logradouro" error={formState.errors.address?.street?.message} />}
            />
            <Controller name="address.number" defaultValue="" control={control} render={({ field }) => <Input {...field} label="Número" error={formState.errors.address?.number?.message} />} />
            <Controller
                name="address.compliment"
                defaultValue=""
                control={control}
                render={({ field }) => <Input {...field} label="Complemento" error={formState.errors.address?.compliment?.message} />}
            />
        </div>
    );
};

export default memo(Form);
