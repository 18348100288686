import { useMemo, useState } from 'react';

import get from 'lodash/get';
import { useOutletContext, useParams } from 'react-router-dom';

import ProjectCreateOrUpdate from './create-or-update';

import { Project, ProjectPayload } from 'types/models/project';
import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';
import useCreateOrUpdateProject from 'services/queries/projects/use-create-or-update-project';
import useGetBranchesOptions from 'services/queries/branches/use-get-branches-options';
import useGetProjectTypesOptions from 'services/queries/projects/use-get-project-types';
import useGetCustomersOptions from 'services/queries/customers/use-get-customers-options';
import useGetCustomerContactsOptions from 'services/queries/customers/use-get-customer-contacts-options';
import useGetProject from 'services/queries/projects/use-get-project';
import { Status } from 'types/general';

const ProjectCreateOrUpdatePage = ({ title }: CrudPageProps) => {
    const [customerId, setCustomerId] = useState<number>();
    const { projectId } = useParams();

    const params = useOutletContext<GraphqlPaginationVariables<Project>>();

    const { data: project } = useGetProject(Number(projectId));
    const { data: branches } = useGetBranchesOptions();
    const { data: customerContacts } = useGetCustomerContactsOptions(customerId);
    const { data: customers, isLoading: isLoadingCustomers } = useGetCustomersOptions();
    const { data: projectTypes, isLoading: isLoadingProjectTypes } = useGetProjectTypesOptions();

    const { mutateAsync: createOrUpdateProject, isLoading: isSubmitting } = useCreateOrUpdateProject(params, Number(projectId));

    const isBuilding = useMemo(() => isLoadingCustomers || isLoadingProjectTypes, [isLoadingCustomers, isLoadingProjectTypes]);

    const handleSubmit = async (data: ProjectPayload) => {
        try {
            await createOrUpdateProject(data);
        } catch (error) {
            console.log('error', error);
        }
    };

    const defaultValues = useMemo(
        () => ({
            branch: project?.branch?.id,
            customer: project?.customer?.id,
            customerContact: project?.customerContact?.id,
            footage: {
                formattedValue: project?.footage || '0',
                value: project?.footage || '0',
                floatValue: Number(project?.footage) || 0
            },
            code: project?.code,
            notes: project?.notes || '',
            status: project?.status || Status.Active,
            title: project?.title || '',
            address: {
                ...get(project, 'address[0].address'),
                city_id: project?.address?.[0]?.address.city?.id,
                state_id: project?.address?.[0]?.address?.state?.id
            },
            types: project?.types?.map((item) => item.type.id || 0).filter(Boolean) || []
        }),
        [project]
    );

    return (
        <ProjectCreateOrUpdate
            defaultValues={defaultValues as any}
            projectTypes={projectTypes || []}
            customerContacts={customerContacts || []}
            customers={customers || []}
            branches={branches || []}
            isBuilding={isBuilding}
            isSubmitting={isSubmitting}
            title={title || ''}
            onSelectCustomer={setCustomerId}
            onSubmit={handleSubmit}
        />
    );
};

export default ProjectCreateOrUpdatePage;
