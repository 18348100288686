import { DatatableColumn } from 'types/graphql';
import { TableCell } from '@mui/material';
import { Display } from 'mui-datatables';
import dictionary from 'utils/dictionary';
import Text from 'components/core/text';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import { ClassificationType } from 'types/models/classification';
import { OperationalDetailsPageContext } from '../view';
import { DrePlannedOutcomesType, OutcomesParam } from './types';
import Badge from 'components/core/badge';

const TH = (value: any) => (
    <TableCell key={value.label}>
        <Text variant="body.medium.sm" as="span" className="text-secondary-500">
            {value.label}
        </Text>
    </TableCell>
);

const peopleColumns = [
    {
        name: 'person.id',
        options: { display: 'excluded' as Display }
    },
    {
        name: 'person.name',
        label: dictionary.content.graphql.name,
        options: {
            customHeadRender: TH
        }
    },
    {
        name: 'hour_value',
        label: 'Valor Hora',
        options: {
            customHeadRender: TH,
            customBodyRender: (value) => formatMoney(value || 0)
        }
    },
    {
        name: 'predicted_time',
        label: 'Horas Previstas',
        options: {
            customHeadRender: TH
        }
    },
    {
        name: 'accomplished_time',
        label: 'Horas Alocadas',
        options: {
            customHeadRender: TH
        }
    },
    {
        name: '',
        label: 'Total',
        options: {
            customHeadRender: TH,
            customBodyRender: (_, rowIndex) => {
                const [, , hourValue, , accomplishedTime] = rowIndex.rowData;

                return formatMoney(hourValue * accomplishedTime || 0);
            }
        }
    }
];

const outcomeColumns = [
    {
        name: 'transaction.id',
        options: { display: 'excluded' as Display }
    },
    {
        name: 'transaction.code',
        label: dictionary.content.graphql.code,
        options: {
            customHeadRender: TH
        }
    },
    {
        name: 'transaction.description',
        label: dictionary.content.graphql.description,
        options: {
            customHeadRender: TH
        }
    },
    {
        name: 'transaction.due_date',
        label: dictionary.content.graphql.due_date,
        options: {
            customHeadRender: TH,
            customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY')
        }
    },
    {
        name: 'transaction.payday',
        label: dictionary.content.graphql.payday,
        options: {
            customHeadRender: TH,
            customBodyRender: (value) => (value ? formatDate(value, 'DD/MM/YYYY') : '--')
        }
    },
    {
        name: 'transaction.value',
        label: dictionary.content.graphql.value,
        options: {
            customHeadRender: TH,
            customBodyRender: (value) => formatMoney(value || 0)
        }
    }
];

export const outcomesTypeWithTable: DrePlannedOutcomesType[] = ['partners', 'plannedOutcomes', 'teamCostExecution', 'teamCostManagement'];

export const literalContentDataByOutcomeType = (data) => ({
    teamCostExecution: {
        cardsLabelsWithValues: [
            {
                labelName: 'Valor Previsto',
                value: data?.teams?.execution?.predicted || 0
            },
            {
                labelName: 'Valor Realizado',
                value: data?.teams?.execution?.performed || 0
            }
        ]
    },
    teamCostManagement: {
        cardsLabelsWithValues: [
            {
                labelName: 'Valor Previsto',
                value: data?.teams?.management?.predicted || 0
            },
            {
                labelName: 'Valor Realizado',
                value: data?.teams?.management?.performed || 0
            }
        ]
    },
    partners: {
        cardsLabelsWithValues: [
            {
                labelName: 'Valor Previsto',
                value: data?.provider?.predicted || 0
            },
            {
                labelName: 'Valor Realizado',
                value: data?.provider?.performed || 0
            }
        ]
    },
    plannedOutcomes: {
        cardsLabelsWithValues: [
            {
                labelName: 'Valor Previsto',
                value: data?.expenses?.predicted || 0
            },
            {
                labelName: 'Valor Realizado',
                value: data?.expenses?.performed || 0
            }
        ]
    },
    profit: {
        cardsLabelsWithValues: [
            {
                labelName: 'Valor Previsto',
                value: data?.profit?.predicted || 0,
                percentage: data?.profit?.predictedPercentage || 0
            },
            {
                labelName: 'Valor Realizado',
                value: data?.profit?.performed || 0,
                percentage: data?.profit?.percentage || 0
            }
        ]
    },
    adminCost: {
        cardsLabelsWithValues: [
            {
                labelName: 'Imposto',
                value: data?.administrativeValues?.tax || 0,
                percentage: data?.administrativeValues?.taxPercentagem || 0
            },
            {
                labelName: 'Taxa Administrativa',
                value: data?.administrativeValues?.administrative || 0,
                percentage: data?.administrativeValues?.administrativePercentage || 0
            }
        ]
    },
    result: {
        cardsLabelsWithValues: [
            {
                labelName: 'Valor Previsto',
                value: data?.result?.predicted || 0,
                percentage: data?.result?.percentage || 0
            },
            {
                labelName: 'Valor Realizado',
                value: data?.result?.performed || 0,
                percentage: data?.result?.percentage || 0
            }
        ]
    }
});

export const filterOutcomesByClassification = (data, classificationType: ClassificationType) => (classificationName: string, isDiff?: boolean) => {
    if (isDiff) {
        data?.filter((item) => item?.transaction?.classification.type !== classificationType && item?.transaction?.classification?.name !== classificationName);
    }

    return data?.filter((item) => item?.transaction?.classification.type === classificationType && item?.transaction?.classification?.name === classificationName);
};

export const literalDataTable = (outcomes: OutcomesParam, isManagement: boolean, ctx: Partial<OperationalDetailsPageContext>) => {
    const filteredOutcomes = filterOutcomesByClassification(outcomes?.items, ClassificationType.FinancialClassification);

    return {
        partners: isManagement ? filteredOutcomes('Residentes') : filteredOutcomes('Parceiros'),
        teamCostManagement: ctx?.projectProposalType?.project_type_person_resources?.filter((people) => (people as any).manager && !people.provider),
        teamCostExecution: ctx?.projectProposalType?.project_type_person_resources?.filter((people) => !(people as any).manager && !people.provider),
        plannedOutcomes: isManagement ? filteredOutcomes('Residentes', true) : filteredOutcomes('Parceiros', true)
    };
};

export const columnsByOutcomeType: { [key: string]: DatatableColumn[] } = {
    partners: outcomeColumns,
    plannedOutcomes: outcomeColumns,
    teamCostManagement: peopleColumns,
    teamCostExecution: peopleColumns
};

export const generateLabelPercentageWithBadge = (label = 0) => <Badge variant={label < 0 ? 'error' : 'success'}>{label}%</Badge>;

export const DREHeadColumns = [
    { label: '', className: 'border-r-[40px] border-r-transparent' },
    { label: 'CONTRATO' },
    { label: 'PLANEJADO' },
    { label: 'REALIZADO' },
    { label: 'SALDO' },
    { label: 'PREVISTO ETC' },
    { label: 'FORECAST EAC' },
    { label: 'VAR' }
];
