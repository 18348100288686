import React from 'react';
import { RouteObject } from 'react-router-dom';
import PageHeader from 'components/page/header';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';

const financial: RouteObject[] = [
    {
        path: 'financeiro',
        element: <Page Component={PageHeader} code="financeiro" title="Financeiro" permissions={[Rule.List]} />
    }
];

export default financial;
