const dictionary = {
    validation: {
        required: 'Campo obrigatório',
        invalid: {
            email: 'E-mail inválido'
        },
        equal: {
            password: 'As senhas não são iguais'
        },
        integer: 'O valor precisa ser do tipo número',
        date: {
            min: 'Data final menor que inicial',
            max: ''
        },
        number: {
            min: (value: number) => `O valor mínimo permitido é ${value}`,
            max: (value: number) => `O valor máximo permitido é ${value}`
        },
        string: {
            min: (value: number) => `O valor deve ter no mínimo ${value} caractere(s)`,
            max: (value: number) => `O valor deve ter no máximo ${value} caractere(s)`
        },
        array: {
            min: (value: number) => `É necessário ao menos ${value} item`,
            max: (value: number) => `É necessário no máximo ${value} item`
        },
        type: (type: string) => `O valor inserido deve ser do tipo ${type}`
    },
    content: {
        graphql: {
            code: 'Código',
            title: 'Título',
            'customer.title': 'Cliente',
            'branch.name': 'Unidade',
            'project_status.title': 'Status',
            status: 'Status',
            name: 'Nome',
            initials: 'Iniciais',
            permalink: 'Link',
            slug: 'Slug',
            email: 'E-mail',
            username: 'Nome de usuário',
            'profile.name': 'Perfil',
            juridic_name: 'Nome jurídico',
            juridic_fantasy_name: 'Nome fantasia',
            juridic_document: 'CNPJ',
            marital_status: 'Estado civil',
            document: 'CPF',
            birthday: 'Data de nascimento',
            gender: 'Gênero',
            phones: 'Telefones',
            'types.project_type.title': 'Tipo de projeto',
            job_position: 'Posição',
            start_date: 'Data inicial',
            final_date: 'Data final',
            classification: 'Classificação',
            partner: 'Parceiro',
            salary: 'Salário',
            shift: 'Turno',
            file: 'Arquivo',
            observations: 'Observações',
            key_rule: 'Chave',
            controller: 'Controller',
            'subpages.page.title': 'Subpáginas',
            icon: 'Ícone',
            order_menu: 'Ordem',
            serial_number: 'Número de série',
            description: 'Descrição',
            payday: 'Data de pagamento',
            due_date: 'Data de vencimento',
            value: 'Valor',
            number: 'Código',
            subject: 'Assunto',
            person: 'Criador',
            bank: 'Banco',
            account: 'Conta',
            accountDigit: 'Dígito Conta',
            agency: 'Agência',
            agencyDigit: 'Dígito Agência',
            mainBankAccount: 'Conta Bancária Principal',
            department: 'Departamento',
            brand: 'Marca',
            model: 'Modelo'
        },
        datatable: {
            body: {
                noMatch: 'Nenhum resultado encontrado.',
                toolTip: 'Ordenar'
            },
            pagination: {
                next: 'Próxima',
                previous: 'Anteiror',
                rowsPerPage: 'Registros por página:',
                displayRows: 'de'
            },
            selectedRows: {
                delete: 'Apagar',
                deleteAria: 'Apagar linhas selecionadas',
                text: 'linha(s) selecionada(s)'
            },
            filter: {
                all: 'TODOS',
                reset: 'RESETAR',
                title: 'FILTROS'
            },
            viewColumns: {
                title: 'Exibir colunas',
                titleAria: 'Exibir/esconder colunas'
            },
            toolbar: {
                filterTable: 'Abrir filtros',
                print: 'Imprimir',
                search: 'Buscar',
                viewColumns: 'Mostrar/ocultar'
            }
        },
        manager_responsibility: 'Responsabilidade de gerenciamento',
        objectives: 'Objetivos',
        premises: 'Instalações',
        restrictions: 'Restrições',
        risks: 'Riscos',
        type: 'Tipo',
        code: 'Código',
        'customer.title': 'Cliente',
        'branch.name': 'Unidade',
        'director.name': 'Responsável',
        genders: {
            female: 'Feminino',
            male: 'Masculino',
            other: 'Outro'
        },
        meetingRecordStatus: {
            deleted: 'Apagada',
            inactive: 'Rascunho',
            active: 'Publicada'
        },
        maritalStatus: {
            single: 'Solteiro(a)',
            married: 'Casado(a)',
            divorced: 'Divorciado(a)',
            widowed: 'Viúvo(a)'
        },
        jobSeniorities: {
            intern: 'Estagiário',
            junior: 'Júnior',
            intermediate: 'Pleno',
            senior: 'Senior'
        },
        projectTypes: {
            management: 'Gerenciamento',
            projects: 'Contratos'
        },
        detachments: {
            enterprise: 'Empresarial',
            own: 'Próprio'
        },
        signpostDimensions: {
            small: '0,60m x 0,60m',
            medium: '0,85m x 0,70m',
            large: '2m x 1m'
        },
        signpostTypes: {
            sticker: 'Adesivo',
            canvas: 'Lona',
            metallic: 'Metálica'
        },
        shifts: {
            day: 'Diurno',
            night: 'Noturno'
        },
        frequencies: {
            partTime: 'Part time',
            fullTime: 'Full time'
        },
        payments: {
            unique: 'Única',
            monthly: 'Mensal',
            lpu: 'LPU'
        },
        contractTypes: {
            personal: 'CLT',
            juridic: 'Pessoa jurídica',
            intern: 'Estagiário',
            associate: 'Associado'
        },
        typeManagementInformations: {
            director: 'Diretor',
            manager: 'Gerente',
            resident: 'Residente',
            coordinator: 'Coordenador',
            production: 'Produção'
        },
        personContractType: {
            promotion: 'Promoção',
            resignation: 'Demissão',
            situation: 'Situação',
            reactivate: 'Reativar'
        },
        personContractStatusTitle: {
            Active: 'Ativo',
            MaternityLeave: 'Licença Maternidade',
            SicknessAllowance: 'Auxílio Doença',
            Vacation: 'Férias',
            MedicalCertificate: 'Atestado',
            Transferred: 'Transferido',
            Finished: 'Encerrado',
            Resignation: 'Demissão'
        },
        person: {
            email: 'E-mail'
        },
        termTypes: {
            jobSiteKnowledge: 'Termo de conhecimento de local de obra',
            projectKnowledge: 'Termo de conhecimento dos projetos',
            materialValidation: 'Termo de validação de materiais',
            temporaryWorkClosure: 'Termo de encerramento obra-provisório',
            finalWorkingClosing: 'Termo de encerramento obra-definitivo'
        },
        attentionPointTypes: {
            relevant: 'Relevante',
            critical: 'Crítico'
        },
        assetsStatus: {
            available: 'Disponível',
            inUse: 'Em Uso',
            breakdown: 'Avaria'
        },
        assetsModalities: {
            leased: 'Alugada',
            patrimony: 'Patrimônio',
            own: 'Próprio'
        }
    }
};

export default dictionary;
