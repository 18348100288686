import { Fragment } from 'react';
import { Control, Controller, FormState, useFieldArray } from 'react-hook-form';
import Text from 'components/core/text';
import AddButton from 'components/buttons/add';
import Input from 'components/core/form/input';
import RemoveButton from 'components/buttons/remove';
import { ProjectProposalPayload } from 'types/models/project';

type ScopeProps = {
    control: Control<ProjectProposalPayload, any>;
    formState: FormState<ProjectProposalPayload>;
};

export const DEFAULT_SCOPE = {
    duration: 0,
    title: ''
};

const Scope = ({ control, formState }: ScopeProps) => {
    const { fields, append, remove } = useFieldArray({ control, name: 'phases' });

    const handleCreateScope = () => append(DEFAULT_SCOPE);

    const handleDeleteScope = (index: number) => () => remove(index);

    return (
        <Fragment>
            {fields.map((item, fieldIndex) => {
                const isLastItem = fields.length - 1 === fieldIndex;

                return (
                    <Fragment key={item.id}>
                        <div className="group mb-5 border border-base-300 p-4 rounded-[14px] relative">
                            <div className="bg-base-300 flex items-center justify-center rounded-full absolute -right-2 -top-4 h-[32px] w-[32px]">
                                <Text as="span" variant="body.medium.2xs" className="text-base-700">
                                    {fieldIndex + 1}
                                </Text>
                            </div>
                            {Boolean(fieldIndex) && <RemoveButton onClick={handleDeleteScope(fieldIndex)} />}
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                <Controller
                                    name={`phases.${fieldIndex}.title`}
                                    control={control}
                                    render={({ field }) => <Input {...field} error={formState.errors.phases?.[fieldIndex]?.title?.message} label="Título" />}
                                />
                                <Controller
                                    name={`phases.${fieldIndex}.duration`}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            error={formState.errors.phases?.[fieldIndex]?.duration?.message}
                                            label="Duração"
                                            right={
                                                <Text as="span" variant="body.regular.xs" className="text-heading">
                                                    /dias
                                                </Text>
                                            }
                                            rightClasses="bg-base-200 px-4"
                                        />
                                    )}
                                />
                                <Controller
                                    name={`phases.${fieldIndex}.paymentPercentage`}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            error={formState.errors.phases?.[fieldIndex]?.paymentPercentage?.message}
                                            label="Percentual de liberação de pagamento"
                                            right={
                                                <Text as="span" variant="body.regular.xs" className="text-heading">
                                                    %
                                                </Text>
                                            }
                                            rightClasses="bg-base-200 px-4"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {isLastItem && <AddButton onClick={handleCreateScope}>Adicionar escopo</AddButton>}
                    </Fragment>
                );
            })}
            {Boolean((formState as any).errors.phases?.message) && (
                <Text as="span" variant="body.regular.xs" className="text-system-danger-500 italic block w-full text-center">
                    {(formState as any).errors.phases?.message}
                </Text>
            )}
        </Fragment>
    );
};

export default Scope;
