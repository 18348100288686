// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { ProjectType } from 'types/models/project';
import project, { getProjectProposalTypesKey } from './graphql';

type Response = {
    project_type: Pick<ProjectType, 'id' | 'title' | 'project_proposal_types'>[];
};

const useGetProjectProposalTypes = (proposalId?: number, projectId?: number) => {
    const fetcher = () => apiGraphQl<Response>(project.getProjectProposalTypes, { proposalId, projectId }).then((data) => data.project_type);

    return useQuery(getProjectProposalTypesKey(proposalId, projectId), fetcher, { enabled: Boolean(proposalId) });
};

export default useGetProjectProposalTypes;
