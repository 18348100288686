import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import CreateProposalBaseForm from '../components/form';
import CurrencyInput from 'components/core/form/currency';
import { Controller } from 'react-hook-form';

import theme from 'settings/theme';
import projectBaseSchema from '../utils/schema';
import { CreateProposalPageProps } from '../utils/types';
import { ProjectProposalPayload } from 'types/models/project';
import { useEffect, useState } from 'react';

const CreateBidProposal = ({ projectProposalId, projectTypeId, isBuilding, isSubmitting, onSubmit, defaultValues, projectStatusSlug }: CreateProposalPageProps) => {
    const navigate = useNavigate();
    const handleClose = () => navigate(-1);

    const [duration, setDuration] = useState('');

    const percentageAverage = (value: any, context: any) => {
        if (!Array.isArray(value)) {
            return true;
        }

        const average = (value || []).reduce((acc, curr) => acc + Number(curr.paymentPercentage), 0);

        if (average !== 100) {
            return false;
        }

        methods.clearErrors(context.path);

        return true;
    };

    const methods = useForm<ProjectProposalPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(projectBaseSchema(duration, projectProposalId, Number(projectTypeId), percentageAverage)),
        defaultValues: {
            budgetExpense: {
                formattedValue: '',
                value: ''
            },
            budgetProvider: {
                formattedValue: '',
                value: ''
            },
            investmentType: '',
            duration: undefined,
            investment: {
                formattedValue: '',
                value: ''
            },
            observations: ''
        }
    });

    const durationWatcher = methods.watch('duration');

    useEffect(() => {
        setDuration(durationWatcher);
    }, [durationWatcher]);

    return (
        <Modal closeOnClickOutside={false} contentClassnames="w-[100%] sm:w-auto mx-5 sm:min-w-[500px] max-w-[1124px]" onClose={handleClose}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="py-5 px-7" onSubmit={methods.handleSubmit(onSubmit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            Proposta {'>'} BID
                        </Text>
                        <CreateProposalBaseForm
                            defaultValues={defaultValues}
                            projectStatusSlug={projectStatusSlug}
                            childrenInner={
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                                    <div className="border border-base-300 p-4 rounded-[14px] grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <Controller
                                            name="numberOfManagementHours"
                                            control={methods.control}
                                            render={({ field }) => {
                                                return (
                                                    <CurrencyInput
                                                        value={field?.value?.floatValue}
                                                        error={(methods.formState as any).errors?.numberOfManagementHours?.message}
                                                        placeholder=""
                                                        label="HH Gestão"
                                                        onValueChange={(values) => methods.setValue('numberOfManagementHours', values)}
                                                    />
                                                );
                                            }}
                                        />
                                        <Controller
                                            name="valueOfManagementHours"
                                            control={methods.control}
                                            render={({ field }) => (
                                                <CurrencyInput
                                                    value={field?.value?.floatValue}
                                                    error={(methods.formState as any).errors?.valueOfManagementHours?.message}
                                                    placeholder=""
                                                    label="Valor HH Gestão"
                                                    left={
                                                        <Text as="span" variant="body.regular.xs" className="text-heading">
                                                            R$
                                                        </Text>
                                                    }
                                                    leftClasses="bg-base-200 px-4"
                                                    onValueChange={(values) => methods.setValue('valueOfManagementHours', values)}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="border border-base-300 p-4 rounded-[14px] grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <Controller
                                            name="numberOfExecutionHours"
                                            control={methods.control}
                                            render={({ field }) => (
                                                <CurrencyInput
                                                    value={field?.value?.floatValue}
                                                    error={(methods.formState as any).errors?.numberOfExecutionHours?.message}
                                                    placeholder=""
                                                    label="HH Execução"
                                                    onValueChange={(values) => methods.setValue('numberOfExecutionHours', values)}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="valueOfExecutionHours"
                                            control={methods.control}
                                            render={({ field }) => (
                                                <CurrencyInput
                                                    value={field?.value?.floatValue}
                                                    error={(methods.formState as any).errors?.valueOfExecutionHours?.message}
                                                    label="Valor HH Execução"
                                                    placeholder=""
                                                    left={
                                                        <Text as="span" variant="body.regular.xs" className="text-heading">
                                                            R$
                                                        </Text>
                                                    }
                                                    leftClasses="bg-base-200 px-4"
                                                    onValueChange={(values) => methods.setValue('valueOfExecutionHours', values)}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            }
                            {...methods}
                        />
                        <div className="flex items-center mt-4">
                            <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default CreateBidProposal;
