import { Operational } from 'types/models/operationals';
import { Pagination } from 'types/pagination';
import Datatable from 'components/core/table/datatable';
import { Rule } from 'types/permissions';
import { DatatableColumn, ItemsPerPage } from 'types/graphql';
import { formatDate } from 'utils/date';
import Badge from 'components/core/badge';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import TableCell from '@mui/material/TableCell/TableCell';
import Card from 'components/core/card';
import { operationalTableSortByColumnName, projectBadgeStatuses } from '../utils';
import { OperationalOrderByValue } from '../quick-filter';

const columns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' }
    },
    {
        name: 'late',
        options: { display: 'excluded' }
    },
    {
        name: 'project.code',
        label: 'Código',
        options: {
            customBodyRender: (value, row) => {
                const isLate = Boolean(row?.rowData[1]);

                return (
                    <div className="flex flex-col">
                        {value}

                        {isLate && (
                            <Badge variant="error" className="px-[10px] py-[6px] mt-1 mr-auto">
                                Atrasado
                            </Badge>
                        )}
                    </div>
                );
            }
        }
    },
    {
        name: 'project.customer.title',
        label: 'Cliente'
    },
    {
        name: 'project.title',
        label: 'Nome'
    },
    {
        name: 'startDate',
        label: 'Início',
        options: {
            customBodyRender: (value) => formatDate(value)
        }
    },
    {
        name: 'endDate',
        label: 'Término',
        options: {
            customBodyRender: (value) => formatDate(value)
        }
    },
    {
        name: 'project.footage',
        label: 'Metragem (m²)',
        options: {
            customBodyRender: (value) => Number(value).toLocaleString('pt-BR')
        }
    },
    {
        name: 'manager.title',
        label: 'Gerente'
    },
    {
        name: 'projectTypeStatus',
        label: 'Status',
        options: {
            customBodyRender: (value) => {
                const [variant, label] = projectBadgeStatuses.get(value) || [];

                if (!variant || !label) {
                    return null;
                }

                return (
                    <Badge variant={variant as any} className="px-[10px] py-[6px]">
                        {label}
                    </Badge>
                );
            }
        }
    },
    {
        name: 'progress',
        label: 'Progresso',
        options: {
            customBodyRender: (value) => {
                return (
                    <div className="flex flex-col">
                        <span className="ml-auto">{value}%</span>

                        <LinearProgress
                            color="success"
                            value={value > 100 ? 100 : value < 0 ? 0 : value}
                            variant="determinate"
                            classes={{ root: 'h-[6px] rounded-[3px] bg-base-300 w-full', bar: 'rounded-[3px]' }}
                        />
                    </div>
                );
            }
        }
    },
    {
        name: 'actions',
        label: 'Ações',
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
        },
        customRoutePath: ``
    }
];

type OperationalListTableProps = {
    data?: Pagination<Operational>;
    page: number;
    orderBy?: OperationalOrderByValue;
    totalPerPage?: ItemsPerPage;
    onChangePage(page: number): void;
    onChangeSort?: React.Dispatch<React.SetStateAction<OperationalOrderByValue | undefined>>;
    onChangeRowsPerPage?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const OperationalsListTable = ({ data, page, orderBy, totalPerPage, onChangePage, onChangeSort, onChangeRowsPerPage }: OperationalListTableProps) => {
    return (
        <Card className="py-1 px-1 mb-6">
            <Datatable
                actions={[Rule.Show]}
                columns={columns}
                data={data?.items || []}
                loading={false}
                options={{
                    elevation: 0,
                    sort: true,
                    filter: false,
                    search: false,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: true,
                    count: data?.total,
                    page,
                    rowsPerPage: totalPerPage,
                    sortOrder: {
                        name: orderBy?.title || '',
                        direction: (orderBy?.direction?.toLowerCase() || 'asc') as any
                    },
                    onChangePage,
                    onChangeRowsPerPage,
                    onColumnSortChange: (changedColumn, direction) => {
                        const field = operationalTableSortByColumnName[changedColumn];
                        onChangeSort?.((item) => ({
                            title: changedColumn,
                            field: field,
                            direction: item?.field === field ? (item?.direction === 'ASC' ? 'DESC' : 'ASC') : direction?.toUpperCase()
                        }));
                    }
                }}
                withItemsPerPage={true}
                hideFooter={false}
            />
        </Card>
    );
};

export default OperationalsListTable;
