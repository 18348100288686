// Dependencies
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import api from 'services/api';
import { getAllKey } from '../crud/graphql';
import useToast from 'hooks/toast/use-toast';
import { createProjectKey } from './graphql';
import { GraphqlPaginationVariables } from 'types/graphql';
import { Project, ProjectPayload } from 'types/models/project';

const useCreateOrUpdateProject = (params: GraphqlPaginationVariables<Project>, projectId?: number) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: ProjectPayload) => {
        const method = Boolean(projectId) ? api.patch : api.post;
        const endpoint = Boolean(projectId) ? `/projects/${projectId}` : `/projects`;

        return method(endpoint, payload);
    };

    return useMutation(createProjectKey, request, {
        onSuccess: ({ data }) => {
            queryClient.invalidateQueries(getAllKey('project', params));

            navigate(`../${data.id}`);

            showToast('Contrato criado com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao criar o contrato', 'danger');
        }
    });
};

export default useCreateOrUpdateProject;
