// Helpers
import { EnumType, VariableType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import isEmpty from 'lodash/isEmpty';
import { ApprovalStatus, Status } from 'types/general';
import { ProjectStatusEnum, ProjectTypeEnum, ProjectTypeManagementInformation, ProjectTypeStatus } from 'types/models/project';
import { TimelineType } from 'types/models/timeline';
import { convertObjectToGraphqlOrderBy, removeFalsyValues } from 'utils/object';

export const createProjectKey = ['project', 'create'];

export const getProjectKey = (projectId?: number | null) => ['project', projectId];

export const projectTypesOptionsKey = ['project', 'types', 'options'];
export const getProjectTypeKey = (projectTypeId?: string) => ['project', 'type', projectTypeId];
export const updateProjectProposalTypeKey = (projectTypeId?: string) => ['project', 'proposal', 'type', 'update', projectTypeId];

export const releaseTapKey = (projectId: number) => [...getProjectKey(projectId), ProjectStatusEnum.Tap];
export const createProjectManagementInformationKey = (projectId?: number) => [...getProjectKey(projectId), 'management-information', 'create'];
export const updateProjectManagementInformationKey = (projectId?: number, cisoId?: string) => [...getProjectKey(projectId), 'management-information', 'update', cisoId];

export const projectSignpostsOptionsKey = ['project', 'signposts', 'options'];
export const createProjectSignpostsKey = (projectId?: number) => [...getProjectKey(projectId), 'signposts', 'create'];

export const projectMeasuresOptionsKey = ['project', 'types', 'options'];

export const createProjectProposalKey = ['project', 'proposal', 'create'];
export const getProjectProposalTypeKey = (projectProposalTypeId?: number) => ['proposal', projectProposalTypeId];
export const getProjectProposalTypeByPkKey = (projectProposalTypeId?: string) => ['project', 'proposal', 'type', projectProposalTypeId];
export const getProjectProposalSensitiveDataKey = (projectProposalTypeId: number, projectId?: number) => [...getProjectKey(projectId), 'proposal', 'type', projectProposalTypeId];

export const getProjectManagementInformationKey = (managementInformationId?: number) => ['project', 'management-information', managementInformationId];

export const getProjectServicesByTypeKey = (type: ProjectTypeEnum) => ['project', 'service', type];

export const getProjectMethodologiesKey = ['project', 'methodologies'];

export const proposalTypeAggregateKey = ['project', 'proposal', 'manager'];

export const getProjectProposalTypesKey = (proposalId?: number, projectId?: number) => [...getProjectKey(projectId), 'proposal', proposalId, 'types'];

export const addProjectTypeResourcesKey = (proposalTypeId?: number, projectId?: number) => [...getProjectKey(projectId), 'type', proposalTypeId, 'add', 'resources'];

export const getCisoKey = (cisoId?: string) => ['ciso', cisoId];
export const getCisosKey = (projectId?: number) => ['cisos', projectId];
export const getActiveCisoKey = (projectId?: number) => ['ciso', projectId, 'active'];

export const getMeetingRecordKey = (meetingRecordId?: string) => ['meeting-record', meetingRecordId];
export const createOrUpdateMeetingRecordKey = (meetingRecordId?: string) => ['meeting-record', 'create', 'update', meetingRecordId];
export const publishMeetingRecordKey = (meetingRecordId?: string) => ['meeting-record', 'publish', meetingRecordId];
export const getProjectMeetingRecordsKey = (variables: any, projectId?: number) => [...getProjectKey(projectId), 'meeting-record', 'all', variables];
export const getSubjectsByMeetingRecordKey = (meetingRecordId?: number) => ['meeting-record', 'subjects', meetingRecordId];

export const getProjectTypeDocumentKey = (projectProposalTypeId?: string, projectTypeDocumentId?: string) => ['documents', projectProposalTypeId, projectTypeDocumentId];
export const getProjectTypeDocumentsKey = (projectProposalTypeId?: number) => ['documents', projectProposalTypeId];
export const createOrUpdateProjectTypeDocumentsKey = (projectProposalTypeId?: string, documentId?: string) => ['documents', 'create', 'update', projectProposalTypeId, documentId];

export const getProjectTypeTermKey = (projectTypeTermId?: string) => ['terms', projectTypeTermId];
export const getProjectTypeTermsKey = (projectProposalTypeId?: number) => ['terms', projectProposalTypeId];
export const createOrUpdateProjectTypeTermKey = (projectProposalTypeId?: number, termId?: string) => ['terms', 'create', 'update', projectProposalTypeId, termId];

export const getProjectTypeSolicitationKey = (projectTypeSolicitationId?: string) => ['solicitations', projectTypeSolicitationId];
export const getProjectTypeSolicitationsKey = (query: string) => ['solicitations', query];
export const createOrUpdateProjectTypeSolicitationKey = (projectProposalTypeId?: string, solicitationId?: string) => ['solicitations', 'create', 'update', projectProposalTypeId, solicitationId];

export const createTaskKey = ['tasks', 'create'];
export const getTaskStatusesKey = (type: TimelineType) => ['tasks', 'status', type];

export const getChecklistKey = (projectProposalTypeId?: string) => ['checklist', projectProposalTypeId];
export const createChecklistKey = (projectProposalTypeId?: number) => ['checklist', 'create', projectProposalTypeId];

export const getChecklistsKey = (projectProposalTypeId?: string) => ['checklist', 'all', projectProposalTypeId];
export const createTimelineKey = (projectProposalTypeId?: string) => ['timeline', 'create', projectProposalTypeId];

export const getTimelineKey = (timelineId?: string | null) => ['timeline', timelineId];

export const getProjectDocumentsKey = (projectId?: string) => ['project', projectId, 'documents'];
export const getProjectDocumentKey = (projectId?: string, documentId?: string) => ['project', projectId, 'documents', documentId];
export const createOrUpdateProjectDocumentKey = (projectId?: string, documentId?: string) => ['project', projectId, 'document', documentId];

export const deleteProjectTypeDocumentKey = (projectTypeId?: number) => ['project-type', projectTypeId, 'document', 'delete'];
export const deleteProjectTypeTermKey = (projectTypeId?: number) => ['project-type', projectTypeId, 'term', 'delete'];

export const deleteProjectDocumentKey = (projectId?: string) => ['project', projectId, 'documents', 'delete'];

export const getProjectChartsKey = (projectTypeId?: string) => ['project_type', projectTypeId, 'charts'];
export const getPhaseOptionsByClassificationKey = (classificationFatherId: number, projectServices?: number[]) => ['phases', classificationFatherId, projectServices];
export const getProjectProposalResourceById = (resourceId?: string) => ['get', 'project_type', 'resource', resourceId];

export const getProjectsKey = ['get', 'all', 'projects'];

const queries = {
    getProject: `query GetProject ($id: Int!) {
        project: project_by_pk(id: $id) {
            id
            branch { id }
            code
            title
            notes
            status
            types {
                type: project_type {
                    id
                    slug
                    title
                }
            }
            proposal {
                id
                proposal_types {
                    id
                    progress
                    project_type {
                        id
                        slug
                        title
                    }
                    resources_aggregate {
                        aggregate {
                            count
                        }
                    }
                    project_type_person_resources_aggregate {
                        aggregate {
                          count
                        }
                    }
                }
            }
            address(where: {address: {status: {_eq: ${Status.Active}}}}) {
                address {
                    id
                    compliment
                    neighbourhood
                    number
                    street
                    zip
                    city {
                        id
                        name
                    }
                    state {
                        id
                        name
                        initials
                    }
                }
            }
            customer {
                id
                title
                contacts {
                    person {
                        name
                        phones
                    }
                }
                file { 
                    filename
                    path
                }
            }
            project_status {
                id
                title
                slug
            }
            project_signposts {
                id
                dimension
                quantity
                type
                signpost {
                    title
                    url
                }
            }
            project_management_informations {
                id
                approval_status
                revision
                duration
                management_end_date
                management_start_date
                signpost_class {
                    id
                    slug
                    title
                }
                measures: project_management_information_has_measures {
                    project_measure {
                      title
                      id
                    }
                }
            }
            project_documents {
                document {
                    name
                    observation
                    type
                    file {
                        filename
                        original_name
                        path
                        id
                        path_tmp
                    }
                }
            }
            footage
        }
    }`,
    getProjectTypesOptions: `query GetProjectTypesOptions {
        items: project_type(where: {status: {_eq: ${Status.Active}}}) {
          value: id
          label: title
        }
    }`,
    getProjectSignpostsOptions: `query GetProjectSignpostsOptions {
        items: signpost_class(order_by: {title: asc}, where: {status: {_eq: ${Status.Active}}}) {
          value: id
          label: title
        }
    }`,
    getProjectMeasuresOptions: `query GetProjectMeasuresOptions {
        items: project_measure(order_by: {title: asc}, where: {status: {_eq: ${Status.Active}}}) {
          value: id
          label: title
          slug: slug
        }
    }`,
    getProjectTypesOptionsByProject: `query GetProjectTypesOptionsByProject ($project_id: Int!) {
        items: project_type(where: {status: {_eq: 1}, project_has_types: {project_id: {_eq: $project_id}}}) {
          value: id
          label: title
        }
    }`,
    getProjectProposalType: `query GetProjectProposalTypeByPk ($id: Int!) {
        project_proposal_type_by_pk(id: $id) {
            id
            progress
            deadline_type
            duration
            investment_type
            investment
            budget
            budget_expense
            budget_provider
            percentage_cost_tax
            percentage_cost_administrative
            percentage_profit
            has_entry_installment
            value_of_entry_installment
            start_date
            end_date
            value_of_execution_hours
            value_of_management_hours
            has_phases
            file {
                id
                filename
                path
            }
            director {
                id
                name
                phones
                email
            }
            manager {
                id
                name
                phones
                email
            }
            observations
            phases(order_by: {order_phase: asc}) {
                duration
                id
                order_phase
                payment_percentage
                title
                classification_id
                classification {
                    classifications {
                        id
                    }
                }
                project_proposal_type_phase_has_classifications {
                    classification {
                        id
                        name
                    }
                }
            }
            project_methodology {
                id
                title
            }
            project_proposal_type_has_services {
                project_service {
                    id
                    title
                    type
                }
            }
            start_date
            status
            project_type_person_resources(where: {status: {_eq: ${Status.Active}}}) {
                id
                contract_start_date
                contract_end_date
                salary
                seniority
                type_management_information
                accomplished_time
                predicted_time
                hour_value
                manager
                project_type_person_resource_periods {
                    contract_end_date
                    contract_start_date
                    percentage
                }
                services: project_type_person_resource_has_classifications {
                    classification {
                        id
                        name
                    }
                }
                provider {
                    id
                    title
                }
                person {
                    name
                    id
                    person_contract {
                        job_position {
                            title
                        }
                    }
                    contracts {
                        job_position {
                            title
                        }
                    }
                }
            }
            project_proposal {
                project {
                    id
                    title
                    project_status {
                        slug
                    }
                    project_signposts {
                        id
                        dimension
                        quantity
                        type
                        signpost {
                            title
                            url
                        }
                    }
                    project_management_informations {
                        id
                        approval_status
                        revision
                        duration
                        management_end_date
                        management_start_date
                        signpost_class {
                            id
                            slug
                            title
                        }
                        measures: project_management_information_has_measures {
                            project_measure {
                              title
                              id
                            }
                        }
                    }
                }
            }
            project_type_status
            number_of_execution_hours
            number_of_management_hours
            predicted_progress
            start_work_time
            week_days
            week_first_day_work
            week_last_day_work
            financial_setup: project_proposal_type_financials {
                number_of_execution_hours
                value_of_execution_hours
                budget_provider
                service: project_service {
                    value: id
                    label: title
                }
            }
        }
    }`,
    createProposalType: `mutation CreateProposalType ($payload: project_proposal_type_insert_input!) {
        item: insert_project_proposal_type_one(object: $payload) {
            project_proposal_id
            project_type_id
        }
    }`,
    createProposalResources: `mutation CreateProposalResources ($payload: [project_proposal_type_resource_insert_input!]!) {
        item: insert_project_proposal_type_resource(objects: $payload) {
            returning {
                proposal_type {
                    id
                    project_type_id
                    project_proposal_id
                }
            }
        }
    }`,
    createProjectManagementInformation: `mutation CreateProjectManagementInformation ($payload: project_management_information_insert_input!) {
        item: insert_project_management_information_one(object: $payload) {
            id
        }
    }`,
    updateProjectManagementInformation: `mutation UpdateProjectManagementInformation($updatePayload: project_management_information_set_input!, $createPayload: project_management_information_insert_input!, $id: Int!) {
        update_project_management_information_by_pk(pk_columns: {id: $id}, _set: $updatePayload) {
            id
        }
        insert_project_management_information_one(object: $createPayload) {
            id
        }
    }`,
    getProjectManagementInformation: `query GetProjectManagementInformation ($id: Int!) {
        project_management_information(where: {approval_status: {_eq: ${ApprovalStatus.Active}}, id: {_eq: $id}}) {
        project_management_information(where: {id: {_eq: $id}}) {
            id
            duration
            management_end_date
            management_start_date
            signpost_class {
                id
                slug
                title
            }
            measures: project_management_information_has_measures {
                project_measure {
                  title
                  id
                }
            }
        }
    }`,
    createProjectSignposts: `mutation CreateProjectSignposts ($payload: [project_signpost_insert_input!]!) {
        item: insert_project_signpost(objects: $payload) {
            affected_rows
        }
    }`,
    getProjectType: `query GetProjectType ($projectTypeId: Int!) {
        project_type_by_pk(id: $projectTypeId) {
            id
            title
            slug
        }
    }`,
    getProjectServicesByType: `query GetProjectServicesByType ($type: Int!) {
        items: project_service(order_by: {title: asc}, where: {type: {_eq: $type}, status: {_eq: ${Status.Active}}}) {
            value: id
            label: title
        }
    }`,
    getProjectMethodologies: `query GetProjectMethodologies {
        items: project_methodology(order_by: {title: asc}, where: {status: {_eq: ${Status.Active}}}) {
          value: id
          label: title
        }
    }`,
    updateProjectType: `mutation UpdateProjectType ($payload: project_proposal_type_set_input!, $id: Int!) {
        update_project_proposal_type_by_pk(pk_columns: { id: $id }, _set: $payload) {
            manager {
                user_id
            }
        }
    }`,
    proposalTypeAggregate: `query ProposalTypeAggregate ($where: project_proposal_type_bool_exp) {
        project_proposal_type_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }`,
    getProjectProposalSensitiveData: `query GetProjectProposalSensitiveData ($project_proposal_id: Int!, $project_type_id: Int!) {
        project_proposal_type(where: {project_proposal_id: {_eq: $project_proposal_id}, project_type_id: {_eq: $project_type_id}, status: {_eq: ${Status.Active}}}) {
            id
            investment
            investment_type
            budget
            number_of_execution_hours
            number_of_management_hours
            percentage_cost_administrative
            percentage_cost_tax
            percentage_profit
            value_of_execution_hours
            value_of_management_hours
            week_days
            financial_setup: project_proposal_type_financials {
                number_of_execution_hours
                value_of_execution_hours
                budget_provider
                service: project_service {
                    value: id
                    label: title
                }
            }
            project_type {
                title
                slug
            }
            resources {
                id
                job_position {
                    title
                }
                quantity
                seniority
                frequency
                observations
                shift
            }
        }
    }`,
    getProjectProposalTypes: `query GetProjectProposalTypes($proposalId: Int!, $projectId: Int!) {
        project_type(where: {project_has_types: {project_id: {_eq: $projectId}}}) {
            id
            title
            project_proposal_types(where: {project_proposal_id: {_eq: $proposalId}}) {
                id
            }
        }
    }`,
    getProjectProposalTypeByPk: `query GetProjectProposalTypeByPk ($id: Int!) {
        project_proposal_type_by_pk(id: $id) {
            project_type {
                slug
            }
            managers: project_type_person_resources_aggregate(where: {status: {_eq: ${Status.Active}}, type_management_information: {_eq: ${ProjectTypeManagementInformation.Manager}}}) {
                aggregate {
                    count
                }
            }
            residents: project_type_person_resources_aggregate(where: {type_management_information: {_eq: ${ProjectTypeManagementInformation.Resident}}}) {
                aggregate {
                    count
                }
            }
        }
    }`,
    addProjectTypeResources: `mutation AddProjectTypeResources ($payload: [project_type_person_resource_insert_input!]!) {
        item: insert_project_type_person_resource(objects: $payload) {
            affected_rows
        }
    }`,
    updateProjectTypeResource: `mutation UpdateProjectTypeResource ($projectTypeResourceId: Int!, $payload: project_type_person_resource_set_input) {
        update_project_type_person_resource_by_pk(pk_columns: {id: $projectTypeResourceId}, _set: $payload) { id }
    }`,
    getProjectTypePersonResources: `query GetProjectTypePersonResources {
        project_type_person_resource(where: project_type_person_resource_bool_exp}) {
            id
            contract_end_date
            contract_start_date
            person {
                id
                name
            }
            type_management_information
        }
    }`,
    getCiso: `query GetCiso($cisoId: Int!) {
        project_management_information_by_pk(id: $cisoId) {
            id
            management_end_date
            management_start_date
            duration
            project_id
            signpost_class_id
            status
            revision
            approval_status
            observations
            project_management_information_has_measures {
                project_measure { 
                    id 
                    title 
                    slug
                }
            }
            project_type_person_resources {
                person {
                    name
                    id
                    person_contract {
                        job_position {
                            title
                        }
                    }
                    contracts {
                        job_position {
                            title
                        }
                    }
                }
                contract_end_date
                contract_start_date
                seniority
                type_management_information
                type_of_detachment
                project_type_person_assets {
                    asset_type { name }
                    quantity
                }
            }
            project {
                project_signposts {
                    id
                    dimension
                    quantity
                    type
                    signpost {
                        title
                        url
                    }
                }
            }
        }
    }`,
    getAtiveCiso: (projectId: number) => `query GetActiveCiso {
        project_management_information(where: {status: {_eq: ${Status.Active}}, approval_status: {_eq: ${ApprovalStatus.Active}}, project_id: {_eq: ${projectId}}}, order_by: {approval_status: desc}) {
            id
            management_end_date
            management_start_date
            duration
            project_id
            signpost_class_id
            status
            revision
            approval_status
            observations
            project_management_information_has_measures {
                project_measure { 
                    id 
                    title 
                    slug
                }
            }
            project_type_person_resources {
                contract_end_date
                contract_start_date
                seniority
                type_management_information
                type_of_detachment
                project_type_person_assets {
                    asset_type { name }
                    quantity
                }
            }
            project {
                project_signposts {
                    id
                    dimension
                    quantity
                    type
                    signpost {
                        title
                        url
                    }
                }
            }
        }
    }`,
    getCisos: `query GetCisos($projectId: Int!) {
        project_management_information(where: { project_id: { _eq: $projectId } }, order_by: {approval_status: desc}) {
            id
            management_end_date
            management_start_date
            approval_status
            signpost_class {
                title
            }
        }
    }`,
    getProjectMeetingRecords: `query GetProjectMeetingRecords ($orderBy: [project_meeting_record_order_by!], $where: project_meeting_record_bool_exp, $limit: Int, $offset: Int) {
        items: project_meeting_record(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
            id
            date
            title
            person { name id }
            status
        }
        pagination: project_meeting_record_aggregate (where: $where) {
            aggregate { count }
        }
    }`,
    getSubjectByMeetingRecord: `query GetSubjectsByMeetingRecord ($meetingRecordId: Int!) {
        items: project_meeting_record_subject(where: {status: {_eq: ${Status.Active}}, project_meeting_record_id: {_eq: $meetingRecordId}}) {
            value: id
            label: title
        }
    }`,
    getProjectTypeDocuments: (fields: string) => `query GetProjectTypeDocuments($projectProposalTypeId: Int!) {
        items: project_type_document(where: {project_proposal_type_id: {_eq: $projectProposalTypeId}}) {
            ${fields}
        }
    }`,
    createDocument: `mutation CreateDocument ($payload: project_type_document_insert_input!) {
        item: insert_project_type_document_one(object: $payload) { id }
    }`,
    updateDocument: `mutation UpdateDocument ($projectTypeDocumentId: Int!, $payload: project_type_document_set_input) {
        update_project_type_document_by_pk(pk_columns: {id: $projectTypeDocumentId}, _set: $payload) { id }
    }`,
    getDocument: (fields: string) => `query GetDocument($projectTypeDocumentId: Int!) {
        document: project_type_document_by_pk(id: $projectTypeDocumentId) {
            ${fields}
        }
    }`,
    getTerms: `query GetTermsByProjectProposalType ($projectProposalTypeId: Int!) {
        items: project_type_term(where: {project_proposal_type_id: {_eq: $projectProposalTypeId}}) {
            id
            description
            observations
            person {
                name
                id
            }
        }
    }`,
    getSolicitations: `query GetSolicitationsByProjectProposalType($projectProposalTypeId: Int!) {
        items: project_type_solicitation(where: {project_proposal_type_id: {_eq: $projectProposalTypeId}}) {
            description
            id
            number
            observations
            subject
            person {
                name
                id
            }
        }
    }`,
    getTimelines: `query GetTimelines($projectProposalTypeId: Int!, $type: Int!) {
        timeline(where: {project_proposal_type_id: {_eq: $projectProposalTypeId}, type: {_eq: $type}, status: {_eq: ${Status.Active}}}) {
            id
            provider {
                id 
                title 
            }
            start_date
            end_date
            progress_percentage
            status_creation
        }
    }`,
    getTaskStatuses: `query GetTaskStatuses($type: Int!) {
        task_status(where: {type: {_eq: $type}}, order_by: {id: asc}) {
            value: id
            label: title
        }
    }`,
    updateProjectProposalTypeResource: jsonToGraphQLQuery({
        mutation: {
            __name: 'UpdateProjectProposalResourceById',
            __variables: {
                payload: 'project_proposal_type_resource_set_input',
                id: 'Int!'
            },
            item: {
                __aliasFor: 'update_project_proposal_type_resource_by_pk',
                __args: {
                    pk_columns: { id: new VariableType('id') },
                    _set: new VariableType('payload')
                },
                proposal_type: {
                    id: true
                }
            }
        }
    }),
    getProjectProposalResourceById: jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetProjectProposalResourceById',
                __variables: {
                    resourceId: 'Int!'
                },
                items: {
                    __aliasFor: 'project_proposal_type_resource_by_pk',
                    __args: {
                        id: new VariableType('resourceId')
                    },
                    id: true,
                    job_position_id: true,
                    observations: true,
                    seniority: true,
                    quantity: true,
                    shift: true,
                    frequency: true
                }
            }
        },
        { pretty: true }
    ),
    getProjects: (params) => {
        const paramsWhere = !!params.where ? removeFalsyValues(params.where) : {};
        const isWhereEmpty = isEmpty(paramsWhere);
        const [orderByKey] = Object.entries(params.orderBy)[0] || [];

        const where = {
            status: { _eq: Status.Active },
            ...(!isWhereEmpty && paramsWhere)
        };

        const orderByValue = convertObjectToGraphqlOrderBy(params.orderBy);

        return jsonToGraphQLQuery(
            {
                query: {
                    __name: 'GetProjects',
                    items: {
                        __aliasFor: 'project',
                        __args: {
                            limit: params.limit,
                            offset: params.offset,
                            ...(Boolean(orderByKey) && {
                                order_by: orderByValue
                            }),
                            where
                        },
                        id: true,
                        code: true,
                        title: true,
                        customer: {
                            title: true
                        },
                        types: {
                            project_type: {
                                title: true
                            }
                        },
                        movements: {
                            __args: {
                                where: {
                                    project_status: {
                                        id: { _eq: ProjectTypeStatus.Started }
                                    }
                                },
                                limit: 1,
                                order_by: { created_at: new EnumType('desc') }
                            },
                            created_at: true
                        }
                    },
                    pagination: {
                        __aliasFor: 'project_aggregate',
                        __args: {
                            where
                        },
                        aggregate: {
                            count: true
                        }
                    }
                }
            },
            { pretty: true }
        );
    }
};

export default queries;
