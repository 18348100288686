import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Select from 'components/core/form/select';
import CreateProposalBaseForm from '../components/form';
import theme from 'settings/theme';
import projectBaseSchema from '../utils/schema';
import { CreateProposalPageProps } from '../utils/types';
import { ProjectProposalPayload, ProjectStatusEnum, ProjectTypeEnum } from 'types/models/project';
import useGetProjectMethodologies from 'services/queries/projects/use-get-project-methodology';
import useGetProjectServiceByType from 'services/queries/projects/use-get-project-service-by-type';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { classificationsQuery } from './utils';
import ProjectPhase from './phase';
import hexToRgba from 'utils/hex-to-rgba';
import Switch from 'components/core/switch';
import findIndex from 'lodash/findIndex';
import CurrencyInput from 'components/core/form/currency';
import { useEffect, useMemo, useState } from 'react';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import { Option } from 'types/general';
import { isEmpty } from 'lodash';

type CreateProjectProposalProps = CreateProposalPageProps & {
    title: string;
    projectType: ProjectTypeEnum;
};

const CreateProjectProposalContent = ({ projectProposalId, projectTypeId, isBuilding, isSubmitting, onSubmit, defaultValues, title, projectType, projectStatusSlug }: CreateProjectProposalProps) => {
    const navigate = useNavigate();
    const handleClose = () => navigate(-1);
    const { palette } = useTheme();

    const isProject = useMemo(() => projectType === ProjectTypeEnum.Projects, [projectType]);
    const isComplementaryProject = useMemo(() => projectType === ProjectTypeEnum.ComplementaryProjects, [projectType]);

    const { data: methodologies = [] } = useGetProjectMethodologies();
    const { data: servicesOptions = [] } = useGetProjectServiceByType(projectType);
    const { data: classifications = [] } = useGetClassifications(classificationsQuery(isComplementaryProject));

    const [duration, setDuration] = useState('');

    const methods = useForm<ProjectProposalPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(projectBaseSchema(duration, projectProposalId, Number(projectTypeId))),
        defaultValues: {
            observations: '',
            phases: defaultValues?.phases || [],
            financialSetups: defaultValues?.financialSetups || []
        }
    });

    const durationWatcher = methods.watch('duration');

    useEffect(() => {
        setDuration(durationWatcher);
    }, [durationWatcher]);

    const isPreTap = projectStatusSlug === ProjectStatusEnum.PreTap;

    const services = methods.watch('services');
    const hasEntryInstallment = methods.watch('hasEntryInstallment');

    const { fields: phases, remove, append } = useFieldArray({ control: methods.control, name: 'phases' });

    const { fields: financialSetupsFields, remove: removeFinancialSetup, append: appendFinancialSetup } = useFieldArray({ control: methods.control, name: 'financialSetups' });

    const handleEntry = (value: boolean) => {
        methods.setValue('hasEntryInstallment', value);

        if (hasEntryInstallment) {
            return methods.setValue('valueOfEntryInstallment', undefined);
        }
    };

    useEffect(() => {
        if (!isEmpty(defaultValues)) {
            methods.reset(defaultValues);
        }
    }, [defaultValues, methods]);

    const handleChangeSubjects = (selectedOptions: Option[], newValue) => {
        const ids = selectedOptions.map((item) => item.value) as number[];

        if (!Boolean(ids?.length)) {
            methods.setValue('selectedPhases', undefined);
            methods.setValue('phases', undefined);
        }

        methods.setValue('services', ids);

        const removed = newValue.removedValue?.value;

        if (Boolean(removed)) {
            const removedIndex = findIndex(financialSetupsFields, { service: { value: removed } });

            return removeFinancialSetup(removedIndex);
        }

        return appendFinancialSetup({
            value_of_execution_hours: 0,
            number_of_execution_hours: 0,
            service: newValue.option
        });
    };

    const handleChangePhases = (options: any[], newValue) => {
        methods.setValue(
            'selectedPhases',
            options.map((item) => item.value)
        );

        const removed = newValue.removedValue?.value;

        if (Boolean(removed)) {
            const removedIndex = findIndex(phases, { classification: removed });

            return remove(removedIndex);
        }

        return append({
            classification: newValue.option.value,
            paymentPercentage: 0,
            hasThirdPart: newValue.option.has_third_part
        });
    };

    return (
        <Modal closeOnClickOutside={false} contentClassnames="w-[100%] sm:w-auto mx-5 sm:min-w-[500px] max-w-[1124px]" onClose={handleClose}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="py-5 px-7" onSubmit={methods.handleSubmit(onSubmit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            Proposta {'>'} {title}
                        </Text>
                        <CreateProposalBaseForm
                            withPhases={false}
                            defaultValues={defaultValues}
                            projectType={projectType}
                            childrenInner={
                                <>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <Controller
                                                name="numberOfManagementHours"
                                                control={methods.control}
                                                render={({ field }) => {
                                                    return (
                                                        <CurrencyInput
                                                            name={field.name}
                                                            ref={field.ref}
                                                            value={field?.value?.value}
                                                            error={(methods.formState as any).errors?.numberOfManagementHours?.message}
                                                            placeholder=""
                                                            label="HH Gestão"
                                                            onValueChange={(values) => field.onChange(values)}
                                                        />
                                                    );
                                                }}
                                            />
                                            <Controller
                                                name="valueOfManagementHours"
                                                control={methods.control}
                                                render={({ field }) => (
                                                    <CurrencyInput
                                                        name={field.name}
                                                        ref={field.ref}
                                                        value={field?.value?.floatValue}
                                                        error={(methods.formState as any).errors?.valueOfManagementHours?.message}
                                                        placeholder=""
                                                        label="Valor HH Gestão"
                                                        left={
                                                            <Text as="span" variant="body.regular.xs" className="text-heading">
                                                                R$
                                                            </Text>
                                                        }
                                                        leftClasses="bg-base-200 px-4"
                                                        onValueChange={(values) => field.onChange(values)}
                                                    />
                                                )}
                                            />
                                        </div>
                                        {!isProject && !isComplementaryProject && (
                                            <div className="border border-base-300 p-4 rounded-[14px] grid grid-cols-1 sm:grid-cols-2 gap-4">
                                                <Controller
                                                    name="numberOfExecutionHours"
                                                    control={methods.control}
                                                    render={({ field }) => (
                                                        <CurrencyInput
                                                            name={field.name}
                                                            ref={field.ref}
                                                            value={field?.value?.floatValue}
                                                            error={(methods.formState as any).errors?.numberOfExecutionHours?.message}
                                                            placeholder=""
                                                            label="HH Execução"
                                                            onValueChange={(values) => field.onChange(values)}
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    name="valueOfExecutionHours"
                                                    control={methods.control}
                                                    render={({ field }) => (
                                                        <CurrencyInput
                                                            name={field.name}
                                                            ref={field.ref}
                                                            value={field?.value?.floatValue}
                                                            error={(methods.formState as any).errors?.valueOfExecutionHours?.message}
                                                            label="Valor HH Execução"
                                                            placeholder=""
                                                            left={
                                                                <Text as="span" variant="body.regular.xs" className="text-heading">
                                                                    R$
                                                                </Text>
                                                            }
                                                            leftClasses="bg-base-200 px-4"
                                                            onValueChange={(values) => field.onChange(values)}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </>
                            }
                            {...methods}>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                                <Controller
                                    name="services"
                                    control={methods.control}
                                    render={({ field }) => {
                                        const value = servicesOptions.filter((item) => field.value?.find((value) => value === item.value));

                                        return (
                                            <Select
                                                {...field}
                                                isMulti={true}
                                                value={value}
                                                options={servicesOptions}
                                                label="Disciplinas"
                                                placeholder="Selecione uma ou mais opções"
                                                error={(methods as any).formState.errors?.services?.message}
                                                onChange={(option: any, newValue) => handleChangeSubjects(option, newValue)}
                                            />
                                        );
                                    }}
                                />
                                <Controller
                                    name="methodology"
                                    control={methods.control}
                                    render={({ field }) => {
                                        const value = methodologies.find((item) => item?.value === field.value);

                                        return (
                                            <Select
                                                {...field}
                                                value={value}
                                                options={methodologies}
                                                label="Metodologia"
                                                placeholder="Selecione uma opção"
                                                error={methods.formState.errors?.methodology?.message}
                                                onChange={(option: any) => field.onChange(option.value)}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            {(isProject || isComplementaryProject) && (
                                <div className="mb-5">
                                    {financialSetupsFields?.map((service, index) => (
                                        <div key={`${index}_service`} className="border border-base-300 p-4 rounded-[14px] mb-4">
                                            <Text variant="body.medium.2xs" className="text-heading mb-2 block">
                                                {service?.service?.label}
                                            </Text>
                                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                                                <div>
                                                    <Controller
                                                        name={`financialSetups.${index}.number_of_execution_hours`}
                                                        control={methods.control}
                                                        render={({ field }) => (
                                                            <CurrencyInput
                                                                ref={field.ref}
                                                                name={field.name}
                                                                value={field.value}
                                                                decimalScale={0}
                                                                allowLeadingZeros={true}
                                                                autoComplete="nope"
                                                                className="w-auto"
                                                                placeholder="HH Execução"
                                                                label="HH Execução"
                                                                onValueChange={({ floatValue = 0 }) => field.onChange(floatValue)}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <Controller
                                                    name={`financialSetups.${index}.value_of_execution_hours`}
                                                    control={methods.control}
                                                    render={({ field }) => (
                                                        <CurrencyInput
                                                            left={
                                                                <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                    R$
                                                                </Text>
                                                            }
                                                            name={field.name}
                                                            ref={field.ref}
                                                            value={field.value}
                                                            decimalSeparator=","
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            allowLeadingZeros={true}
                                                            autoComplete="nope"
                                                            className="w-auto"
                                                            placeholder="Ex: 100,00"
                                                            label="Valor Execução"
                                                            onValueChange={({ floatValue = 0 }) => field.onChange(floatValue)}
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    name={`financialSetups.${index}.budget_provider`}
                                                    control={methods.control}
                                                    render={({ field }) => (
                                                        <div className="flex relative w-[100%] md:w-auto">
                                                            <Tooltip placement="top" title="Previsão de Custos de terceiro" className="absolute top-[-6px] right-[0px]" disableFocusListener={true}>
                                                                <IconButton TouchRippleProps={{ classes: { child: 'text-system-info-100' } }} className="hover:bg-primary-100 hover:bg-opacity-20">
                                                                    <Icon name="ico-info" width={16} height={16} color={palette.info.main} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <CurrencyInput
                                                                value={field?.value}
                                                                ref={field.ref}
                                                                name={field.name}
                                                                placeholder="Ex: 100,00"
                                                                label="Custo de Terceiros"
                                                                left={
                                                                    <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                        R$
                                                                    </Text>
                                                                }
                                                                decimalSeparator=","
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                allowLeadingZeros={true}
                                                                leftClasses="bg-base-200 px-4"
                                                                onValueChange={({ floatValue = 0 }) => field.onChange(floatValue)}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {isPreTap && (
                                <>
                                    <Controller
                                        name="selectedPhases"
                                        control={methods.control}
                                        render={({ field }) => {
                                            const value = classifications.filter((item) => field.value?.find((value) => value === item.value));

                                            return (
                                                <Select
                                                    {...field}
                                                    isDisabled={!Boolean(services?.length)}
                                                    isMulti={true}
                                                    value={value}
                                                    options={classifications}
                                                    label="Escopos"
                                                    placeholder="Selecione uma ou mais opções"
                                                    parentClassName="mb-6"
                                                    error={(methods as any).formState.errors?.selectedPhases?.message}
                                                    onChange={(options: any, newValue) => handleChangePhases(options, newValue)}
                                                />
                                            );
                                        }}
                                    />
                                    {phases?.map((item, index) => {
                                        const classification = classifications.find((classi) => classi.value === item?.classification);

                                        return (
                                            <ProjectPhase
                                                isComplementaryProject={isComplementaryProject}
                                                index={index}
                                                classificationName={classification?.label || ''}
                                                phase={item as any}
                                                key={item.classification}
                                            />
                                        );
                                    })}
                                </>
                            )}
                        </CreateProposalBaseForm>
                        {isPreTap && (
                            <>
                                <div className="-mx-7 px-7 py-5 mb-5" style={{ backgroundColor: hexToRgba(theme.extend.colors.secondary[100], 0.2) }}>
                                    <div className="flex justify-between items-center">
                                        <Text variant="body.medium.sm" as="span" className="text-secondary-700">
                                            Este projeto possui entrada?
                                        </Text>
                                        <Switch value={!!hasEntryInstallment} onChange={handleEntry} />
                                    </div>
                                </div>
                                {hasEntryInstallment && (
                                    <Controller
                                        name="valueOfEntryInstallment"
                                        control={methods.control}
                                        render={({ field }) => (
                                            <CurrencyInput
                                                name={field.name}
                                                ref={field.ref}
                                                value={field?.value?.floatValue}
                                                error={(methods.formState as any).errors?.valueOfEntryInstallment?.message}
                                                label="Percentual"
                                                placeholder=""
                                                right={
                                                    <Text as="span" variant="body.regular.xs" className="text-heading">
                                                        %
                                                    </Text>
                                                }
                                                rightClasses="bg-base-200 px-4"
                                                onValueChange={(values) => field.onChange(values)}
                                            />
                                        )}
                                    />
                                )}
                            </>
                        )}
                        <div className="flex items-center mt-4">
                            <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default CreateProjectProposalContent;
