import { RouteObject } from 'react-router-dom';
import TableCell from '@mui/material/TableCell/TableCell';

import ProjectPage from 'pages/private/projects/view';
import ProjectCreateOrUpdatePage from 'pages/private/projects/create-or-update';
import CreateOrUpdateTasksPage from 'pages/private/projects/tasks';
import MeetingRecordPage from 'pages/private/projects/meeting-records/view';
import CreateOrUpdateMeetingRecordPage from 'pages/private/projects/meeting-records/create-or-update';

import dictionary from 'utils/dictionary';
import { CrudPageProps } from 'types/graphql';
import CreateProposalPage from 'pages/private/projects/proposal';
import CreateOrUpdateResourcesPage from 'pages/private/projects/proposal/resource';
import CreateOrUpdateDocumentPage from 'pages/private/projects/documents/create-or-update';
import CisoViewPage from 'pages/private/projects/ciso/view';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import { formatDate } from 'utils/date';
import projectQueries from 'services/queries/projects/graphql';
import ListPage from 'pages/private/crud/list';
import Badge from 'components/core/badge';

const [list, create]: CrudPageProps[] = [
    {
        graphql: {
            table: 'project',
            excludedSearchColumnsName: ['movements', 'types'],
            query: projectQueries.getProjects,
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'code',
                    label: dictionary.content.graphql['code'],
                    options: {
                        customBodyRender: (value = '-') => value
                    }
                },
                {
                    name: 'title',
                    label: dictionary.content.graphql['title'],
                    options: {
                        customBodyRender: (value = '-') => value
                    }
                },
                {
                    name: 'customer.title',
                    label: dictionary.content.graphql['customer.title'],
                    options: {
                        customBodyRender: (value = '-') => value
                    }
                },
                {
                    name: 'branch.name',
                    label: dictionary.content.graphql['branch.name'],
                    options: {
                        customBodyRender: (value = '-') => value
                    }
                },
                {
                    name: 'types',
                    label: 'Programas',
                    options: {
                        sort: false,
                        customBodyRender: (value = []) => {
                            return (
                                <div className="flex gap-2 flex-wrap">
                                    {value.map((item, index) => (
                                        <Badge variant="info" key={index}>
                                            {item.project_type.title}
                                        </Badge>
                                    ))}
                                </div>
                            );
                        }
                    }
                },
                {
                    name: 'movements',
                    label: 'TAP',
                    options: {
                        sort: false,
                        customBodyRender: (value = []) => formatDate(value?.[0]?.created_at)
                    }
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        sort: false,
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ]
        },
        title: 'Contratos',
        api: {
            useApiTodelete: true,
            endpoint: '/projects'
        }
    },
    {
        title: 'Novo contrato'
    }
];

const projects: RouteObject[] = [
    {
        path: 'contratos',
        element: <Page Component={ListPage} code="contratos" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={ProjectCreateOrUpdatePage} withModal={true} code="contratos" permissions={[Rule.Create]} {...create} />
            },
            {
                path: 'editar/:projectId',
                element: <Page Component={ProjectCreateOrUpdatePage} withModal={true} code="contratos" permissions={[Rule.Update]} {...create} />
            }
        ]
    },
    {
        path: 'contratos/:projectId',
        element: <Page Component={ProjectPage} code="contratos" permissions={[Rule.Show]} />,
        children: [
            {
                path: 'proposta/:projectProposalId/tipo/:projectTypeId',
                element: <Page Component={CreateProposalPage} withModal={true} code="contratos" permissions={[Rule.Create]} />
            },
            {
                path: 'editar',
                element: <Page Component={ProjectCreateOrUpdatePage} withModal={true} code="contratos" permissions={[Rule.Update]} {...create} />
            },
            {
                path: 'proposta/:projectProposalId/tipo/:projectTypeId/editar/:proposalId',
                element: <Page Component={CreateProposalPage} withModal={true} code="contratos" permissions={[Rule.Update]} />
            },
            {
                path: 'proposta/:projectProposalId/tipo/:proposalTypeId/recursos',
                element: <Page Component={CreateOrUpdateResourcesPage} withModal={true} code="contratos" permissions={[Rule.Create]} />
            },
            {
                path: 'proposta/:projectProposalId/tipo/:proposalTypeId/recursos/:resourceId/editar',
                element: <Page Component={CreateOrUpdateResourcesPage} withModal={true} code="contratos" permissions={[Rule.Update]} />
            },
            {
                path: 'atas-de-reuniao/novo',
                element: <Page Component={CreateOrUpdateMeetingRecordPage} withModal={true} code="contratos" permissions={[Rule.Create]} />
            },
            {
                path: 'atas-de-reuniao/:meetingRecordId',
                element: <Page Component={MeetingRecordPage} withModal={true} code="contratos" permissions={[Rule.Show]} />
            },
            {
                path: 'atas-de-reuniao/:meetingRecordId/editar',
                element: <Page Component={CreateOrUpdateMeetingRecordPage} withModal={true} code="contratos" permissions={[Rule.Update]} />
            },
            {
                path: 'atas-de-reuniao/:meetingRecordId/tarefas',
                element: <Page Component={CreateOrUpdateTasksPage} withModal={true} code="contratos" permissions={[Rule.Create, Rule.Update]} />
            },
            {
                path: 'documentos/novo',
                element: <Page Component={CreateOrUpdateDocumentPage} withModal={true} code="contratos" permissions={[Rule.Create]} />
            },
            {
                path: 'documentos/:documentId/editar',
                element: <Page Component={CreateOrUpdateDocumentPage} withModal={true} code="contratos" permissions={[Rule.Update]} />
            },
            {
                path: 'cisos/:cisoId',
                element: <Page Component={CisoViewPage} withModal={true} code="contratos" withActions={false} permissions={[Rule.Show]} />
            }
        ]
    }
];

export default projects;
