import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Controller, UseFormReturn } from 'react-hook-form';
import Text from 'components/core/text';
import Switch from 'components/core/switch';
import Input from 'components/core/form/input';
import Scope from '../scope';
import Select from 'components/core/form/select';
import DatePicker from 'components/core/datepicker';
import Textarea from 'components/core/form/textarea';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import Icon from 'components/core/icon';
import theme from 'settings/theme';
import { payments } from 'utils/statics';
import hexToRgba from 'utils/hex-to-rgba';
import { ProjectProposalPayload, ProjectStatusEnum, ProjectTypeEnum } from 'types/models/project';
import useGetPeopleOptions from 'services/queries/people/use-get-people-options';
import CurrencyInput from 'components/core/form/currency';
import { PersonType } from 'types/models/person';
import { toCurrencyObject } from 'utils/object';

const MAX_PERCENTAGE_ALLOWED = 100;

type CreateProposalBaseFormProps = {
    childrenInner?: React.ReactNode;
    withPhases?: boolean;
    defaultValues?: Partial<ProjectProposalPayload> | undefined;
    projectStatusSlug?: ProjectStatusEnum;
    projectType?: ProjectTypeEnum;
} & UseFormReturn<ProjectProposalPayload, any>;

const infoButton = { classes: { child: 'text-system-info-100' } };

const CreateProposalBaseForm: React.FC<CreateProposalBaseFormProps> = ({
    control,
    children,
    childrenInner,
    formState,
    withPhases = true,
    setValue,
    watch,
    reset,
    defaultValues,
    projectStatusSlug,
    projectType
}) => {
    const { palette } = useTheme();

    const { data: people } = useGetPeopleOptions({ where: { type: { _eq: PersonType.Internal } } as any });

    const isProject = useMemo(() => projectType === ProjectTypeEnum.Projects, [projectType]);
    const isComplementaryProject = useMemo(() => projectType === ProjectTypeEnum.ComplementaryProjects, [projectType]);

    const gestaoInputParentClasses = classNames(isComplementaryProject || isProject ? 'md:w-auto' : 'md:w-[160px]');

    const startDate = watch('startDate');
    const endDate = watch('endDate');
    const duration = watch('duration');
    const hasScopes = watch('hasPhases');
    const admCostPercentage = watch('percentageCostAdministrative');
    const admTaxPercentage = watch('percentageCostTax');
    const profitPercentage = watch('percentageProfit');

    const hasDates = useMemo(() => startDate || endDate, [startDate, endDate]);

    useEffect(() => {
        if (!!defaultValues) {
            reset(defaultValues);
        }
    }, [reset, defaultValues]);

    useEffect(() => {
        const percentage = 100;

        const value = percentage - ((admCostPercentage?.floatValue || 0) + (admTaxPercentage?.floatValue || 0) + (profitPercentage?.floatValue || 0));

        setValue('budget', toCurrencyObject(Math.round(value)));
    }, [admCostPercentage, admTaxPercentage, profitPercentage, setValue]);

    const handlePhases = (value: boolean) => {
        setValue('hasPhases', value);

        if (hasScopes) {
            return setValue('phases', undefined);
        }

        setValue('phases', [{ title: '', duration: 0 }]);
    };

    return (
        <>
            <div className="flex gap-4 md:gap-0 flex-col md:flex-row mb-4">
                <div className="border border-base-300 p-4 rounded-[14px] relative md:mr-4">
                    <Text as="h5" variant="h6" className="mb-4 text-heading">
                        Duração
                    </Text>
                    <div className="flex flex-col gap-[24px]">
                        <div className={classNames('bg-base-200 border border-dashed border-base-300 p-6 rounded-lg relative', duration ? 'opacity-50' : 'opacity-100')}>
                            {duration && <div className="w-full h-full absolute left-0 top-0 cursor-no-drop z-[1]" />}
                            <Text as="h6" variant="body.medium.2xs" className="text-secondary-500 mb-2">
                                Período
                            </Text>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <Controller
                                    name="startDate"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            label="Data inicial"
                                            placeholderText="Selecione uma data"
                                            error={formState.errors?.startDate?.message}
                                            parentClasses="md:w-[250px]"
                                            isClearable={true}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="endDate"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            label="Data final"
                                            placeholderText="Selecione uma data"
                                            minDate={startDate as any}
                                            error={formState.errors?.endDate?.message}
                                            parentClasses="md:w-[250px]"
                                            isClearable={true}
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex items-center self-center">
                            <div className="w-[20px] h-[1px] bg-base-300" />
                            <Text as="span" variant="body.medium.2xs" className="text-secondary-500 mx-4">
                                ou
                            </Text>
                            <div className="w-[20px] h-[1px] bg-base-300" />
                        </div>
                        <div className={classNames('bg-base-200 border border-dashed border-base-300 p-6 rounded-lg relative', hasDates ? 'opacity-50' : 'opacity-100')}>
                            {hasDates && <div className="w-full h-full absolute left-0 top-0 cursor-no-drop z-[1]" />}
                            <Text as="h6" variant="body.medium.2xs" className="text-secondary-500 mb-2">
                                Tempo
                            </Text>
                            <Controller
                                name="duration"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        error={formState.errors.duration?.message}
                                        label="Prazo"
                                        placeholder="Ex: 10"
                                        right={
                                            <Text as="span" variant="body.regular.xs" className="text-heading">
                                                /dias
                                            </Text>
                                        }
                                        rightClasses="bg-base-200 px-4"
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <div className="border border-base-300 p-4 rounded-[14px] relative mb-4">
                        <Text as="h5" variant="h6" className="mb-4 text-heading">
                            Investimento
                        </Text>
                        <Controller
                            name="investment"
                            control={control}
                            render={({ field }) => (
                                <CurrencyInput
                                    value={field?.value?.floatValue}
                                    error={(formState as any).errors?.investment?.message}
                                    placeholder=""
                                    label="Investimento"
                                    left={
                                        <Text as="span" variant="body.medium.sm" className="text-heading">
                                            R$
                                        </Text>
                                    }
                                    leftClasses="bg-base-200 px-4"
                                    parentClassName="mb-4"
                                    onValueChange={(values) => setValue('investment', values)}
                                />
                            )}
                        />
                        <Controller
                            name="investmentType"
                            control={control}
                            render={({ field }) => {
                                const value = payments.find((item) => item.value.toString() === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={payments}
                                        label="Forma de pagamento"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors?.investmentType?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                        parentClassName="mb-4 w-[full]"
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="budget"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <CurrencyInput
                                        {...field}
                                        value={field?.value?.floatValue}
                                        error={(formState as any).errors?.budget?.message}
                                        aria-disabled={true}
                                        aria-readonly={true}
                                        disabled={true}
                                        readOnly={true}
                                        label="Alocação de investimento"
                                        placeholder="Ex: 0"
                                        right={
                                            <Text as="span" variant="body.regular.xs" className="text-heading">
                                                %
                                            </Text>
                                        }
                                        rightClasses="bg-base-200 px-4"
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="border border-base-300 p-4 rounded-[14px] relative">
                        <Controller
                            name="director"
                            control={control}
                            render={({ field }) => {
                                const value = people?.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={people}
                                        label="Responsável"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors?.director?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="border border-base-300 p-4 rounded-[14px] mb-6">
                <Text as="h5" variant="h6" className="mb-4 text-heading">
                    Gestão
                </Text>
                <div className="flex flex-wrap md:flex-nowrap items-baseline gap-4">
                    {!isProject && !isComplementaryProject && (
                        <Controller
                            name="budgetProvider"
                            control={control}
                            render={({ field }) => (
                                <div className="flex relative w-[100%] md:w-auto">
                                    <Tooltip placement="top" title="Previsão de Custos de terceiro" className="absolute top-[-6px] right-[0px]" disableFocusListener={true}>
                                        <IconButton TouchRippleProps={infoButton} className="hover:bg-primary-100 hover:bg-opacity-20">
                                            <Icon name="ico-info" width={16} height={16} color={palette.info.main} />
                                        </IconButton>
                                    </Tooltip>
                                    <CurrencyInput
                                        value={field?.value?.floatValue}
                                        placeholder=""
                                        error={(formState as any).errors?.budgetProvider?.message}
                                        label="Custo de Terceiros"
                                        left={
                                            <Text as="span" variant="body.medium.sm" className="text-heading">
                                                R$
                                            </Text>
                                        }
                                        leftClasses="bg-base-200 px-4"
                                        parentClassName="md:w-[220px]"
                                        onValueChange={(values) => setValue('budgetProvider', values)}
                                    />
                                </div>
                            )}
                        />
                    )}
                    <Controller
                        name="budgetExpense"
                        control={control}
                        render={({ field }) => (
                            <div className="flex relative w-[100%] md:w-auto">
                                <Tooltip placement="top" title="Previsão de despesa" className="absolute top-[-6px] right-[0px]" disableFocusListener={true}>
                                    <IconButton TouchRippleProps={infoButton} className="hover:bg-primary-100 hover:bg-opacity-20">
                                        <Icon name="ico-info" width={16} height={16} color={palette.info.main} />
                                    </IconButton>
                                </Tooltip>
                                <CurrencyInput
                                    placeholder=""
                                    value={field?.value?.floatValue}
                                    error={(formState as any).errors?.budgetExpense?.message}
                                    label="Despesas"
                                    left={
                                        <Text as="span" variant="body.medium.sm" className="text-heading">
                                            R$
                                        </Text>
                                    }
                                    leftClasses="bg-base-200 px-4"
                                    parentClassName="md:w-[200px]"
                                    onValueChange={(values) => setValue('budgetExpense', values)}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        name="percentageCostAdministrative"
                        control={control}
                        render={({ field }) => (
                            <CurrencyInput
                                isAllowed={({ floatValue = 0 }) => floatValue > 0 && floatValue <= MAX_PERCENTAGE_ALLOWED}
                                allowNegative={false}
                                value={field?.value?.floatValue}
                                error={(formState as any).errors?.percentageCostAdministrative?.message}
                                label="% Custo Adm"
                                placeholder=""
                                right={
                                    <Text as="span" variant="body.regular.xs" className="text-heading">
                                        %
                                    </Text>
                                }
                                rightClasses="bg-base-200 px-4"
                                parentClassName="md:w-[250px]"
                                onValueChange={(values) => setValue('percentageCostAdministrative', values)}
                            />
                        )}
                    />
                    <Controller
                        name="percentageCostTax"
                        control={control}
                        render={({ field }) => (
                            <CurrencyInput
                                value={field.value?.floatValue}
                                error={(formState as any).errors?.percentageCostTax?.message}
                                placeholder=""
                                label="% Imposto"
                                right={
                                    <Text as="span" variant="body.regular.xs" className="text-heading">
                                        %
                                    </Text>
                                }
                                rightClasses="bg-base-200 px-4"
                                parentClassName={gestaoInputParentClasses}
                                onValueChange={(values) => setValue('percentageCostTax', values)}
                            />
                        )}
                    />
                    <Controller
                        name="percentageProfit"
                        control={control}
                        render={({ field }) => (
                            <CurrencyInput
                                value={field.value?.floatValue}
                                error={(formState as any).errors?.percentageProfit?.message}
                                label="% Lucro"
                                placeholder=""
                                right={
                                    <Text as="span" variant="body.regular.xs" className="text-heading">
                                        %
                                    </Text>
                                }
                                rightClasses="bg-base-200 px-4"
                                parentClassName={gestaoInputParentClasses}
                                onValueChange={(values) => setValue('percentageProfit', values)}
                            />
                        )}
                    />
                </div>
                {childrenInner}
            </div>
            {children}
            <div className="mb-6">
                <Controller name="observations" control={control} render={({ field }) => <Textarea {...field} label="Observações" error={formState.errors?.observations?.message} />} />
            </div>
            {withPhases && projectStatusSlug === ProjectStatusEnum.PreTap && (
                <>
                    <div className="-mx-7 px-7 py-5 mb-5" style={{ backgroundColor: hexToRgba(theme.extend.colors.secondary[100], 0.2) }}>
                        <div className="flex flex-wrap gap-4 justify-between items-center">
                            <Text variant="body.medium.sm" as="span" className="text-secondary-700">
                                Este projeto terá escopos?
                            </Text>
                            <Switch value={!!hasScopes} onChange={handlePhases} />
                        </div>
                    </div>
                    {hasScopes && <Scope control={control} formState={formState} />}
                </>
            )}
        </>
    );
};

export default CreateProposalBaseForm;
