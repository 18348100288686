import { ProjectTypeRouteSlug } from 'services/queries/operationals/use-get-operational';
import { ProjectTypeStatus } from 'types/models/project';

export const convertProjectTypeSlugInTitle = (projectTypeSlug?: ProjectTypeRouteSlug) => {
    return projectTypeSlug?.toLocaleLowerCase().replaceAll('-', ' ') || '';
};

export const projectBadgeStatuses = new Map([
    [ProjectTypeStatus.Waiting, ['info', 'Aguardando']],
    [ProjectTypeStatus.Started, ['primary', 'Iniciado']],
    [ProjectTypeStatus.Done, ['success', 'Finalizado']],
    [ProjectTypeStatus.Stopped, ['warning', 'Parado']],
    [ProjectTypeStatus.Canceled, ['error', 'Cancelado']],
    [ProjectTypeStatus.Processing, ['grey', 'Processando']]
]);

export const operationalTableSortByColumnName = {
    'project.code': 'pj.code',
    'project.customer.title': 'c.title',
    'project.title': 'pj.title',
    startDate: 'p.startDate',
    endDate: 'p.endDate',
    'project.footage': 'pj.footage',
    'manager.title': 'm.title',
    projectTypeStatus: 'p.projectTypeStatus',
    progress: 'p.progress'
};
