import { Link, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import TableCell from '@mui/material/TableCell/TableCell';
import IconButton from '@mui/material/IconButton/IconButton';
import DataTable, { MUIDataTableColumnDef } from 'mui-datatables';

import Empty from 'components/empty';
import Card from 'components/core/card';
import Icon from 'components/core/icon';
import Text from 'components/core/text';

import { weekDays } from 'utils/date';
import dictionary from 'utils/dictionary';
import { formatMoney } from 'utils/money';
import { ProjectStatus, ProjectStatusEnum, ProjectTypeSlug } from 'types/models/project';
import useGetProjectProposalSensitiveData from 'services/queries/projects/use-get-project-proposal-sensitive-data';
import { useMemo } from 'react';
import ProposalDetails from './details';

type ProjectProposalProps = {
    projectProposalId?: number;
    projectStatus?: Partial<ProjectStatus>;
    types: Array<{
        project_type_id: number | undefined;
        project_proposal_id: number;
        project_type_slug?: string;
    }>;
};

const TH = (value: any) => (
    <TableCell key={value.label}>
        <Text variant="body.medium.sm" as="span" className="text-secondary-500">
            {value.label}
        </Text>
    </TableCell>
);

const ProjectProposal = ({ projectProposalId, projectStatus, types }: ProjectProposalProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const typesRequest = useMemo(
        () =>
            types.map((item) => ({
                project_type_id: item?.project_type_id,
                project_proposal_id: item?.project_proposal_id
            })),
        [types]
    );

    const requests = useGetProjectProposalSensitiveData(typesRequest || []);

    const datas = requests.filter((item) => Boolean(item.data)).map((item) => item.data!);

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'id',
            options: { display: 'excluded' }
        },
        {
            name: 'project_type.title',
            label: 'Tipo',
            options: {
                customHeadRender: TH
            }
        },
        {
            name: 'investment',
            label: 'Investimento',
            options: {
                customBodyRender: (value) => formatMoney(value || 0),
                customHeadRender: TH
            }
        },
        {
            name: 'budget',
            label: 'Alocação de investimento',
            options: {
                customBodyRender: (value, tableMeta) => {
                    const [, , investment] = tableMeta.rowData;
                    const freeToUse = ((investment || 0) * (value || 0)) / 100;

                    return `${value || '-'}% (${formatMoney(freeToUse)})`;
                },
                customHeadRender: TH
            }
        },
        {
            name: 'project_type.slug',
            options: { display: 'excluded' }
        },
        {
            name: 'investment_type',
            label: 'Forma de pagamento',
            options: {
                customBodyRender: (value) => Object.values(dictionary.content.payments)[value - 1] || '-',
                customHeadRender: TH
            }
        },
        {
            name: '',
            label: 'Recursos',
            options: {
                customHeadRender: TH,
                customBodyRender: (_, tableMeta: any) => {
                    const [id, , , , slug] = tableMeta.rowData;

                    if (slug === ProjectTypeSlug.Management && projectStatus?.slug === ProjectStatusEnum.PreTap) {
                        return (
                            <Tooltip placement="top" title="Adicionar recursos à proposta" disableFocusListener={true}>
                                <IconButton
                                    color="primary"
                                    size="small"
                                    sx={{ backgroundColor: palette.primary[300], '&:hover': { backgroundColor: palette.primary.main } }}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        navigate(`proposta/${projectProposalId}/tipo/${id}/recursos`);
                                    }}>
                                    <Icon name="ico-plus" width={10} height={10} color={palette.grey[100]} />
                                </IconButton>
                            </Tooltip>
                        );
                    }

                    return null;
                }
            }
        },
        {
            name: '',
            label: 'Ações',
            options: {
                customHeadRender: () => (
                    <TableCell className="text-sm font-medium text-secondary-500" key="actions" style={{ width: 130 }}>
                        Ações
                    </TableCell>
                ),
                customBodyRender: (_, tableMeta: any) => {
                    const [proposalId, , , , projectTypeSlug] = tableMeta.rowData;

                    const projectTypeFinder = types.find((item) => item?.project_type_slug === projectTypeSlug);

                    const projectTypeId = projectTypeFinder?.project_type_id;

                    return (
                        <>
                            <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                <IconButton
                                    component={Link}
                                    to={`proposta/${projectProposalId}/tipo/${projectTypeId}/editar/${proposalId}`}
                                    className="hover:bg-system-warning-100 hover:bg-opacity-30">
                                    <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                </IconButton>
                            </Tooltip>
                        </>
                    );
                }
            } as any
        }
    ];

    return Boolean(datas.length) ? (
        <DataTable
            columns={columns}
            data={datas}
            title={
                <Text variant="h5" as="h5" className="text-heading !text-base text-center sm:!text-left md:!text-lg xl:!text-xl">
                    Proposta
                </Text>
            }
            options={{
                sort: false,
                elevation: 21,
                filter: false,
                search: false,
                print: false,
                download: false,
                viewColumns: false,
                customToolbar: null as any,
                selectableRows: 'none',
                enableNestedDataAccess: '.',
                expandableRows: true,
                expandableRowsHeader: false,
                expandableRowsOnClick: true,
                pagination: false,
                responsive: 'standard',
                renderExpandableRow: (rowData, rowMeta) => {
                    const item = datas[rowMeta.rowIndex];

                    const handleGoTo = () => navigate(`proposta/${projectProposalId}/tipo/${item.id}/recursos`);

                    const days = (item.week_days || '')
                        .split(';')
                        .map((day) => {
                            const finded = weekDays.find((item) => item.value === Number(day));

                            if (!finded) {
                                return;
                            }

                            return finded.label;
                        })
                        .filter(Boolean)
                        .join(', ');

                    return <ProposalDetails colSpan={rowData.length + 1} item={item} projectStatus={projectStatus} projectProposalId={projectProposalId} days={days} onAddResource={handleGoTo} />;
                }
            }}
            // @ts-ignore
            className="mb-4 overflow-hidden proposals meeting-records"
        />
    ) : (
        <Card className="mb-4 px-6 py-5">
            <Text variant="h5" as="h5" className="text-heading mb-4">
                Proposta
            </Text>
            <Empty title="Nenhuma proposta encontrada." />
        </Card>
    );
};

export default ProjectProposal;
