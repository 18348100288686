import { useCallback, useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import Text from 'components/core/text';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import { PersonContractType } from 'types/models/person';
import { contractTypes, frequencies, jobSeniorities, shifts } from 'utils/statics';
import { Option } from 'types/general';
import _get from 'lodash/get';
import CurrencyInput from 'components/core/form/currency';
import useGetJobPositionsOptions from 'services/queries/job-positions/use-get-job-positions-options';
import DatePicker from 'components/core/datepicker';
import { useLocation } from 'react-router-dom';

type PersonContractProps = {
    branches?: Option[];
    className?: string;
    baseName?: string;
    hideFinalDate?: boolean;
} & UseFormReturn<any, any>;

const MAX_HOUR_SALARY = 168;
const MAX_HOUR_INTERN_SALARY = 120;

const PersonContract = ({ className = '', branches = [], baseName, hideFinalDate = false, ...methods }: PersonContractProps) => {
    const { control, formState, watch, setValue } = methods;

    const { pathname } = useLocation();

    const { data: jobPositions = [] } = useGetJobPositionsOptions(true);

    const getName = useCallback(
        (name: string) => {
            const start = baseName ? `${baseName}.` : '';

            return `${start}${name}`;
        },
        [baseName]
    );

    const getError = (name: string) => _get(formState.errors, `${getName(name)}.message`);

    const [salary = 0, benefits = 0, hourValueField = 0, contractType] = watch([getName('salary'), getName('benefits'), getName('hourValue'), getName('type')]);

    const isJuridic = useMemo(() => contractType === PersonContractType.Juridic, [contractType]);

    const hourValue = useMemo(() => {
        if (!pathname.includes('novo')) {
            return hourValueField;
        }

        if (contractType === PersonContractType.Personal) {
            return (salary + benefits) / MAX_HOUR_SALARY;
        }

        if (contractType === PersonContractType.Intern) {
            return (salary + benefits) / MAX_HOUR_INTERN_SALARY;
        }

        if (contractType === PersonContractType.Juridic || contractType === PersonContractType.Associate) {
            return salary / MAX_HOUR_SALARY;
        }

        return 0;
    }, [salary, benefits, contractType, pathname, hourValueField]);

    useEffect(() => {
        if (!!hourValue) {
            setValue(getName('hourValue'), +hourValue);
        }
    }, [hourValue, setValue, getName]);

    return (
        <div className={`grid grid-cols-1 sm:grid-cols-3 gap-4 ${className}`}>
            <Controller
                name={getName('branch')}
                control={control}
                render={({ field }) => {
                    const value = branches.find((item) => item?.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={branches}
                            label="Alocado na filial"
                            placeholder="Selecione uma opção"
                            error={getError('branch')}
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                    );
                }}
            />
            <Controller
                name={getName('position')}
                control={control}
                render={({ field }) => {
                    const value = jobPositions.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={jobPositions}
                            label="Cargo"
                            placeholder="Selecione uma opção"
                            error={getError('position')}
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                    );
                }}
            />
            <Controller
                name={getName('type')}
                control={control}
                render={({ field }) => {
                    const value = contractTypes.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={contractTypes}
                            label="Tipo de contratação"
                            placeholder="Selecione uma opção"
                            error={getError('type')}
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                    );
                }}
            />
            {isJuridic && (
                <>
                    <Controller name={getName('juridicName')} control={control} render={({ field }) => <Input {...field} label="Nome jurídico" error={getError('juridicName')} />} />
                    <Controller name={getName('juridicFantasyName')} control={control} render={({ field }) => <Input {...field} label="Nome fantasia" error={getError('juridicFantasyName')} />} />
                    <Controller
                        name={getName('juridicDocument')}
                        control={control}
                        render={({ field }) => <Input {...field} label="CNPJ" autoComplete="nope" mask="99.999.999/9999-99" error={getError('juridicDocument')} />}
                    />
                </>
            )}
            <Controller
                name={getName('seniority')}
                control={control}
                render={({ field }) => {
                    const value = jobSeniorities.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={jobSeniorities}
                            label="Senioridade"
                            placeholder="Selecione uma opção"
                            error={getError('seniority')}
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                    );
                }}
            />
            <Controller
                name={getName('shift')}
                control={control}
                render={({ field }) => {
                    const value = shifts.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={shifts}
                            label="Turno"
                            placeholder="Selecione uma opção"
                            error={getError('seniority')}
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                    );
                }}
            />
            <Controller
                name={getName('frequency')}
                control={control}
                render={({ field }) => {
                    const value = frequencies.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={frequencies}
                            label="Peridiocidade"
                            placeholder="Selecione uma opção"
                            error={getError('frequency')}
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                    );
                }}
            />
            <Controller
                name={getName('startDate')}
                control={control}
                render={({ field }) => <DatePicker label="Data inicial" placeholderText="Selecione uma data" error={getError('startDate')} {...field} />}
            />
            {!hideFinalDate && (
                <Controller
                    name={getName('finalDate')}
                    control={control}
                    render={({ field }) => <DatePicker disabled={!field.value} label="Data final" placeholderText="Selecione uma data" error={getError('finalDate')} {...field} />}
                />
            )}
            <div>
                <Controller
                    name={getName('salary')}
                    control={control}
                    render={({ field: input }) => {
                        const { value, onChange, ...field } = input;

                        return (
                            <CurrencyInput
                                {...field}
                                value={value}
                                onValueChange={({ floatValue }) => onChange(floatValue)}
                                label="Salário"
                                placeholder=""
                                error={getError('salary')}
                                left={
                                    <Text as="span" variant="body.medium.sm" className="text-heading">
                                        R$
                                    </Text>
                                }
                                leftClasses="bg-base-200 px-4"
                            />
                        );
                    }}
                />
            </div>
            <div>
                <Controller
                    name={getName('benefits')}
                    control={control}
                    render={({ field: input }) => {
                        const { value, onChange, ...field } = input;

                        return (
                            <CurrencyInput
                                {...field}
                                value={value}
                                onValueChange={({ floatValue }) => onChange(floatValue)}
                                label="Benefícios"
                                placeholder=""
                                error={getError('benefits')}
                                left={
                                    <Text as="span" variant="body.medium.sm" className="text-heading">
                                        R$
                                    </Text>
                                }
                                leftClasses="bg-base-200 px-4"
                            />
                        );
                    }}
                />
            </div>
            <div>
                <Controller
                    name={getName('hourValue')}
                    control={control}
                    render={({ field: input }) => {
                        const { onChange, ...field } = input;

                        return (
                            <CurrencyInput
                                {...field}
                                aria-disabled={true}
                                value={hourValue || 0}
                                decimalScale={2}
                                decimalSeparator=","
                                thousandSeparator="."
                                fixedDecimalScale={true}
                                onValueChange={({ floatValue }) => onChange(floatValue)}
                                label="Valor hora"
                                placeholder=""
                                error={getError('hourValue')}
                                left={
                                    <Text as="span" variant="body.medium.sm" className="text-heading">
                                        R$
                                    </Text>
                                }
                                leftClasses="bg-transparent px-4"
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default PersonContract;
