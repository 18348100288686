import React from 'react';

// Dependecies
import { RouteObject } from 'react-router-dom';
import TableCell from '@mui/material/TableCell/TableCell';

// Components
import CustomerPage from 'pages/private/clients';
import ListPage from 'pages/private/crud/list';
import ClientCreatePage from 'pages/private/clients/create';
import CustomerTabDetails from 'pages/private/clients/details/tabs/details';
import CustomerDetailsContacts from 'pages/private/clients/details/tabs/contacts';
import CustomerDetailsContactsForm from 'pages/private/clients/details/tabs/contacts/form';
import CustomerDetailsProject from 'pages/private/clients/details/tabs/project';

// Helpers
import dictionary from 'utils/dictionary';
import { CrudPageProps } from 'types/graphql';
import { UseTabRouteChildren } from 'hooks/tab/use-tab-route';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import queries from 'services/queries/customers/graphql';
import CrudDetails from 'pages/private/crud/details';
import ContactDetails from 'components/contacts/details';

const queryFields = jsonToGraphQLQuery({
    id: true,
    juridic_document: true,
    juridic_fantasy_name: true,
    juridic_name: true,
    title: true,
    account: { id: true, name: true },
    file: {
        path: true,
        filename: true
    },
    contacts: {
        id: true,
        person: {
            name: true,
            title: true,
            gender: true,
            email: true,
            phones: true,
            file: {
                path: true,
                filename: true
            },
            birthday: true,
            document: true
        }
    },
    type_business: { id: true, title: true },
    address: {
        address: {
            compliment: true,
            neighbourhood: true,
            number: true,
            street: true,
            zip: true,
            city: { name: true },
            state: { initials: true }
        }
    }
});

const [list, create, details]: CrudPageProps[] = [
    {
        graphql: {
            table: 'customer',
            searchableField: 'title',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'title',
                    label: dictionary.content.graphql['title']
                },
                {
                    name: 'juridic_name',
                    label: dictionary.content.graphql['juridic_name']
                },
                {
                    name: 'juridic_fantasy_name',
                    label: dictionary.content.graphql['juridic_fantasy_name']
                },
                {
                    name: 'juridic_document',
                    label: dictionary.content.graphql['juridic_document']
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ],
            orderBy: {
                title: 'asc'
            }
        },
        api: {
            endpoint: '/customers',
            useApiTodelete: true
        },
        title: 'Clientes'
    },
    {
        title: 'Novo cliente'
    },
    {
        details: {
            table: 'customer',
            query: queries.getCustomer(queryFields),
            component: CustomerPage,
            paramName: 'customerId',
            formatResponse: ({ customer }: any) => ({
                ...customer,
                juridic_name: customer?.juridic_name || '',
                juridic_document: customer?.juridic_document || ''
            })
        }
    }
];

const customerDetailsChildren: UseTabRouteChildren[] = [
    {
        index: true,
        element: <Page Component={CustomerTabDetails} code="clientes" permissions={[Rule.Show]} />,
        label: 'Detalhes'
    },
    {
        path: 'contatos',
        element: <Page Component={CustomerDetailsContacts} code="clientes-contatos" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CustomerDetailsContactsForm} withModal={true} code="clientes-contatos" permissions={[Rule.Create]} />
            },
            {
                path: ':contactId',
                element: <Page Component={ContactDetails} code="clientes-contatos" permissions={[Rule.Show]} withModal={true} />
            },
            {
                path: 'editar/:contactId',
                element: <Page Component={CustomerDetailsContactsForm} withModal={true} code="clientes-contatos" permissions={[Rule.Update]} />
            }
        ],
        label: 'Contatos'
    },
    {
        path: 'contratos',
        element: <Page Component={CustomerDetailsProject} code="clientes-contratos" permissions={[Rule.List, Rule.Show]} />,
        label: 'Contratos'
    }
];

const comercial: RouteObject[] = [
    {
        path: 'clientes',
        element: <Page Component={ListPage} code="clientes" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={ClientCreatePage} withModal={true} code="clientes" permissions={[Rule.Create]} {...create} />
            },
            {
                path: 'editar/:customerId',
                element: <Page Component={ClientCreatePage} withModal={true} code="clientes" permissions={[Rule.Update]} title="Editar cliente" {...create} />
            }
        ]
    },
    {
        path: 'clientes/:customerId',
        element: <Page Component={CrudDetails} code="clientes" permissions={[Rule.Show]} {...details} />,
        children: customerDetailsChildren
    }
];

export default comercial;
