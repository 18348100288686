import { useMemo, useState } from 'react';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Task as TaskModel, TaskType } from 'types/models/task';
import Spinner from 'components/core/spinner';
import useTheme from '@mui/material/styles/useTheme';
import Empty from 'components/empty';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Badge from 'components/core/badge';
import { ColorVariant } from 'types/colors';
import { formatDate, timeToMinutes } from 'utils/date';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import ExternalButton from 'components/buttons/external';
import TaskInteractions from '../interactions';
import useProjectTypeSlug from '../../hooks/use-project-type-slug';
import Avatar from '@mui/material/Avatar/Avatar';
import { generateFileUrl } from 'utils/file';

type TaskProps = {
    isLoading: boolean;
    hasError: boolean;
    task?: TaskModel;
    timelineId: string | null;
};

const warningButton = { classes: { child: 'text-system-warning-100' } };

const getVariant = (status: TaskModel['taskStatus']['slug']): ColorVariant => {
    const statuses: any = {
        done: 'success',
        partial: 'warning',
        pending: 'info',
        stopped: 'error',
        processing: 'info'
    };

    return statuses[status!] ?? 'error';
};

const Task = ({ hasError, isLoading, task, timelineId }: TaskProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isManagement } = useProjectTypeSlug();

    const [urlCopied, setUrlCopied] = useState(false);

    const handleBack = () => navigate(-1);

    const handleCopy = async () => {
        try {
            const url = window.location.href;

            await navigator?.clipboard?.writeText(url);
            setUrlCopied(true);

            setTimeout(() => {
                setUrlCopied(false);
            }, 5000);
        } catch (error) {
            console.log('handleCopy', error);
        }
    };

    const accomplishedTimeClasses = useMemo(() => {
        const accomplishedTime = timeToMinutes(task?.accomplishedTime?.toString() || '0');

        if ((accomplishedTime || 0) === 0) {
            return 'text-base-500';
        }

        const predictedTime = timeToMinutes(task?.predictedTime?.toString() || '0');

        return (accomplishedTime || 0) <= predictedTime || 0 ? 'text-system-success-300' : 'text-system-danger-300';
    }, [task]);

    const handleGoToTaskFather = () => {
        if (pathname.includes('/app/tarefas')) {
            return navigate(`../${task?.taskFather?.id}`, { replace: true });
        }

        return navigate(`../tarefa/${task?.taskFather?.id}`, { replace: true });
    };

    const handleGoToTask = (taskId: number) => {
        if (pathname.includes('/app/tarefas')) {
            return navigate(`/app/tarefas/${taskId}`, { replace: true });
        }

        navigate(`../tarefa/${taskId}`, { replace: true });
    };
    return (
        <Modal
            closeOnClickOutside={false}
            contentClassnames="w-[1000px]"
            headerLeft={
                !isLoading && !hasError ? (
                    <div className="flex flex-wrap gap-2 items-center">
                        <Badge className="mr-2" variant={getVariant(task?.taskStatus?.slug)}>
                            {task?.taskStatus?.title}
                        </Badge>
                        {task?.late && Boolean(task?.endDate) && (
                            <Tooltip title="Esta tarefa está atrasada!" placement="top">
                                <span className="mr-2 text-red-500 rounded bg-red-100 flex items-center justify-center w-[25px] h-[25px] ete">!</span>
                            </Tooltip>
                        )}
                        {Boolean(task?.project?.code) && (
                            <Text as="span" variant="body.regular.xs" className="font-normal text-base-500 italic">
                                Projeto: #{task?.project?.code}
                            </Text>
                        )}
                    </div>
                ) : undefined
            }
            headerRight={
                !isLoading && !hasError ? (
                    <>
                        {!!task?.canReportProgress && (
                            <Tooltip placement="top" title="Informar progresso">
                                <IconButton
                                    TouchRippleProps={warningButton}
                                    className="hover:bg-system-warning-100 hover:bg-opacity-20 ml-2"
                                    onClick={() => navigate(`progresso?timelineId=${timelineId}`)}>
                                    <Icon name="ico-sort" color={palette.warning.main} width={18} height={18} />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip placement="top" title={urlCopied ? 'Copiado' : 'Copiar link'} disableFocusListener={true}>
                            <IconButton color="secondary" sx={{ '&:hover': { backgroundColor: palette.grey[200] } }} onClick={handleCopy}>
                                <Icon name="ico-link" color={palette.grey[700]} width={16} height={16} />
                            </IconButton>
                        </Tooltip>
                    </>
                ) : undefined
            }
            onClose={handleBack}>
            {isLoading ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : hasError ? (
                <Empty title="Tarefa não encontrada" className="p-5" />
            ) : (
                <>
                    <div className="px-6 pb-4">
                        {(task?.title || task?.code) && (
                            <div className="mb-6">
                                {task?.title && (
                                    <>
                                        <div className="flex flex-col sm:flex-row gap-2 sm:items-center">
                                            <Text as="h5" variant="h5" className="text-heading flex-1 !font-bold">
                                                {task?.title}
                                            </Text>
                                            {task?.code && (
                                                <Text as="span" variant="body.regular.xs" className="font-normal text-base-500 italic sm:ml-2">
                                                    #{task?.code}
                                                </Text>
                                            )}
                                        </div>
                                        {task?.taskFather && (
                                            <div className="flex items-baseline">
                                                <Text as="span" variant="body.regular.xs" className="text-base-500 block italic -mb-1 mr-1">
                                                    Tarefa filha de
                                                </Text>
                                                <ExternalButton onClick={handleGoToTaskFather}>{task.taskFather.title}</ExternalButton>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                        {Boolean(task?.provider) && (
                            <div className="mb-6">
                                <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-2">
                                    Atribuída a
                                </Text>
                                <div className="flex">
                                    <Tooltip placement="top" title={task?.provider?.title || ''}>
                                        <Avatar src={generateFileUrl(task?.provider?.image?.filename, task?.provider?.image?.path)} sx={{ borderRadius: '50% !important', height: 30, width: 30 }}>
                                            {task?.provider?.image?.url ? <img src={task?.provider?.image?.url} className="w-full h-full" /> : task?.provider?.title?.charAt(0)}
                                        </Avatar>
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                        {Boolean(task?.person) && (
                            <div className="mb-6">
                                <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-2">
                                    Atribuída a
                                </Text>
                                <div className="flex">
                                    <Link to={`/app/pessoas/${task?.person?.id}`}>
                                        <Tooltip placement="top" title={task?.person?.name || ''}>
                                            <Avatar src={generateFileUrl(task?.person?.file?.filename, task?.person?.file?.path)} sx={{ borderRadius: '50% !important', height: 30, width: 30 }}>
                                                {task?.person?.profileImage?.url ? <img src={task.person?.profileImage?.url} className="w-full h-full" /> : task?.person?.name?.charAt(0)}
                                            </Avatar>
                                        </Tooltip>
                                    </Link>
                                </div>
                            </div>
                        )}
                        {task?.description && (
                            <div className="mb-6">
                                <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-2">
                                    Descrição
                                </Text>
                                <div className="rich-content" dangerouslySetInnerHTML={{ __html: task?.description || '' }} />
                            </div>
                        )}
                        <div className="flex items-start justify-between gap-10 mb-10">
                            {task?.type === TaskType.Progress && (
                                <div className="flex-1">
                                    <div className="mb-7">
                                        <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-2">
                                            Previsto - {task?.predictedPercentage || 0}%
                                        </Text>
                                        {Boolean(task?.predictedPercentage) ? (
                                            <div className="h-3 rounded-[14px] bg-base-300" style={{ width: `${task?.predictedPercentage || 0}%` }} />
                                        ) : (
                                            <Empty title="Previsão não encontrada" />
                                        )}
                                    </div>
                                    <div>
                                        <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-2">
                                            Realizado - {task?.progressPercentage || 0}%
                                        </Text>
                                        {Boolean(task?.progressPercentage) ? (
                                            <div
                                                className={`h-3 rounded-[14px] ${
                                                    (task?.progressPercentage || 0) < (task?.predictedPercentage || 0) ? 'bg-system-danger-300' : 'bg-system-success-300'
                                                }`}
                                                style={{ width: `${task?.progressPercentage || 0}%` }}
                                            />
                                        ) : (
                                            <Empty title="Ainda não existe progresso nesta tarefa" />
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className={task?.type === TaskType.Hour ? 'flex-1 gap-6 flex' : 'flex-1 gap-6'}>
                                {task?.type === TaskType.Hour && (
                                    <div className="flex flex-wrap gap-4 mb-6">
                                        <div>
                                            <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-1">
                                                Tempo previsto:
                                            </Text>
                                            <Text as="span" variant="body.medium.sm" className="text-heading">
                                                {task?.predictedTime || 0} hora(s)
                                            </Text>
                                        </div>
                                        <div>
                                            <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-1">
                                                Tempo realizado:
                                            </Text>
                                            <Text as="span" variant="body.medium.sm" className={accomplishedTimeClasses}>
                                                {task?.accomplishedTime || 0} hora(s)
                                            </Text>
                                        </div>
                                    </div>
                                )}
                                {task?.deadline ? (
                                    <>
                                        <div>
                                            <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-1">
                                                Data de entrega:
                                            </Text>
                                            <Text as="span" variant="body.medium.sm" className="text-heading">
                                                {formatDate(task?.endDate || task?.deadline)}
                                            </Text>
                                        </div>
                                    </>
                                ) : (
                                    <div className={task?.type === TaskType.Hour ? 'flex flex-wrap gap-4' : ''}>
                                        {task?.type === TaskType.Progress && (
                                            <div className="mb-4">
                                                <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-1">
                                                    Duração:
                                                </Text>
                                                <Badge variant="primary">
                                                    {task?.duration || 0} {task?.type === TaskType.Progress ? 'dia' : 'hora'}(s)
                                                </Badge>
                                            </div>
                                        )}
                                        <div className="flex flex-wrap items-start gap-4">
                                            <div>
                                                <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-1">
                                                    Início:
                                                </Text>
                                                <Badge className="capitalize" variant="grey">
                                                    {formatDate(task?.startDate, 'ddd[.] DD.MM.YYYY')}
                                                </Badge>
                                            </div>
                                            <div>
                                                <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-1">
                                                    Fim:
                                                </Text>
                                                <Badge className="capitalize" variant="grey">
                                                    {formatDate(task?.endDate, 'ddd[.] DD.MM.YYYY')}
                                                </Badge>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {Boolean(task?.tasks?.length) && (
                            <>
                                <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-2">
                                    Tarefas anexadas ({task?.tasks?.length || 0})
                                </Text>
                                <div className="mb-6">
                                    {task?.tasks?.map((item) => {
                                        return (
                                            <button
                                                onClick={handleGoToTask.bind(this, item.id)}
                                                className="w-full rounded-xl flex items-center px-4 py-3 bg-base-100 hover:bg-base-200 overflow-hidden"
                                                key={item.id}>
                                                <Text as="span" variant="body.regular.sm" className="text-heading flex-1 mr-2 whitespace-nowrap text-ellipsis overflow-hidden">
                                                    {item.title}
                                                </Text>
                                                <div className="flex items-center">
                                                    {isManagement && (
                                                        <>
                                                            <LinearProgress
                                                                color="secondary"
                                                                value={item.progressPercentage || 0}
                                                                variant="determinate"
                                                                classes={{ root: 'h-[6px] rounded-[3px] bg-base-300 flex-1 w-[100px]', bar: 'rounded-[3px]' }}
                                                            />
                                                            <Text as="span" variant="body.regular.sm" className="text-base-500 ml-2 w-12 text-right">
                                                                {item.progressPercentage || 0}%
                                                            </Text>
                                                        </>
                                                    )}
                                                    <Badge variant={getVariant(item.taskStatus?.slug)} className="ml-2 w-[137px] text-center">
                                                        {item?.taskStatus?.title}
                                                    </Badge>
                                                </div>
                                            </button>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                    <TaskInteractions
                        timelineId={timelineId}
                        comments={task?.comments || []}
                        files={[...(task?.featuredImages || []), ...(task?.files || [])]}
                        progress={task?.progress || []}
                        taskType={task?.type}
                    />
                </>
            )}
        </Modal>
    );
};

export default Task;
