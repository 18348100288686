import { useParams } from 'react-router-dom';
import Project from './project';
import Empty from 'components/empty';
import Spinner from 'components/core/spinner';
import { ProjectProvider } from 'contexts/project';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import useGetProject from 'services/queries/projects/use-get-project';
import useGetProjectProposalTypes from 'services/queries/projects/use-get-project-proposal-types';

const ProjectPage = () => {
    const { projectId } = useParams();

    const { data: project, isLoading: isLoadingProject } = useGetProject(Number(projectId));
    const { data: projectProposalTypes, isLoading: isLoadingProjectProposalTypes } = useGetProjectProposalTypes(project?.proposal?.id, Number(projectId));

    useBreadcrumb(project?.title);

    if (isLoadingProject) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    if (!project) {
        return <Empty title="Contrato não encontrado" />;
    }

    return (
        <ProjectProvider>
            <Project isLoadingProjectProposalTypes={isLoadingProjectProposalTypes} project={project} projectProposalTypes={projectProposalTypes} />
        </ProjectProvider>
    );
};

export default ProjectPage;
