import { Status } from 'types/general';
import { ProjectTypeEnum } from 'types/models/project';

export const createClassificationKey = ['classification', 'create'];

export const createJobPositionKey = ['job_position', 'create'];

export const getClassificationKey = (assetId?: string) => ['classification', assetId];

export const getJobPositionKey = (jobPositionId?: string) => ['job_position', jobPositionId];

export const getTypeBusinessKey = (typeBusinessId?: string) => ['type_business', typeBusinessId];

export const editClassificationAssetKey = (assetId?: string) => ['classification', 'edit', assetId];

export const editJobPositionKey = (assetId?: string) => ['job_position', 'edit', assetId];

export const getClassificationOptionsByTypeKey = (classificationId: number) => ['classification', 'get', 'options', classificationId];

export const getAllProjectServicesKey = ['project_service', 'get', 'all'];

const queries = {
    getClassificationById: `
    query GetClassificationById($classificationId: Int!) {
      items: classification_by_pk(id: $classificationId){
        id,
        name,
        classification {
            id,
            name
        },
        project_service {
            id,
            title
        },
        system
      }
    }
  `,
    getJobPositionById: `
    query GetJobPositionById($jobPositionId: Int!){
        items: job_position_by_pk(id: $jobPositionId){
            id,
            title,
            manager,
            classification_department_id
        }
    }`,
    getClassificationsOptions: `
        query getClassificationOptions($classificationType: Int!) {
            classification(where: {type: {_eq: $classificationType}, classification_id: {_is_null: true}, _and:{status: {_eq: ${Status.Active}}}}, order_by: {name: asc}) {
                value: id,
                label: name
            }
        }
    `,
    getProjectServicesOptions: `
        query getProjectServicesOptions {
            project_service(where: {status: {_eq: ${Status.Active}}, type: {_eq: ${ProjectTypeEnum['Projects']}}}, order_by: {title: asc}) {
            value: id,
            label: title
            }
        }
    `,
    getTypeBusinessById: `
    query GetTypeBusinessById($typeBusinessId: Int!){
        items: type_business_by_pk(id: $typeBusinessId){
            id
            title
        }
    }`
};

export default queries;
