import { useMemo } from 'react';

import CompanyForm from 'components/company/form';

import { CrudPageProps } from 'types/graphql';
import useCreateOrUpdateProvider from 'services/queries/providers/use-create-or-update-provider';
import useGetBusinessTypesOptions from 'services/queries/business-type/use-get-business-types-options';
import { useParams } from 'react-router-dom';
import _omit from 'lodash/omit';
import useGetProvider from 'services/queries/providers/use-get-provider';
import _get from 'lodash/get';
import useUploadFile from 'services/queries/files/use-upload-file';
import { PersonContractType } from 'types/models/person';

const ProvidersCreateOrUpdatePage = ({ title }: CrudPageProps) => {
    const { providerId } = useParams();

    const { data: businessTypes, isLoading: isBuilding } = useGetBusinessTypesOptions();
    const { mutateAsync: createOrUpdateProvider, isLoading: isSubmitting } = useCreateOrUpdateProvider(providerId);

    const { data: provider } = useGetProvider({ providerId });

    const { isLoading: isLoadingFile, uploadFile } = useUploadFile();

    const handleSubmit = async (data: any) => {
        try {
            const hasChangedImage = !data?.file?.id && !!data?.file?.path;

            if (hasChangedImage) {
                await uploadFile(
                    data?.file,
                    ({ id }) => {
                        data.image = id;
                    },
                    true
                );
            } else {
                data.image = data?.file?.id ?? null;
            }

            const payload = {
                ..._omit(data, [
                    'address.status',
                    'status',
                    'juridic_name',
                    'juridic_fantasy_name',
                    'personal_document',
                    'juridic_document',
                    'type_business_id',
                    'type',
                    'account_id',
                    'contacts',
                    'address.city',
                    'address.state',
                    'id',
                    'file',
                    'classifications'
                ]),
                ...(!providerId && {
                    contacts: data?.contacts?.map((person) => ({ person })),
                    classifications: data?.classifications?.map((item) => {
                        return item.subcategory_id ? item?.subcategory_id.value : item?.classification_id.value;
                    })
                }),
                typeBusiness: data?.type_business_id,
                account: 1,
                partner: !!data?.partner,
                type: data?.type,
                ...(data?.type === PersonContractType.Juridic && {
                    juridicName: data?.juridic_name,
                    juridicFantasyName: data?.juridic_fantasy_name,
                    juridicDocument: data?.juridic_document
                }),
                ...(data?.type === PersonContractType.Personal && {
                    personalDocument: data?.personal_document
                })
            };

            await createOrUpdateProvider(payload);
        } catch (error) {
            console.log('error', error);
        }
    };

    const defaultValues = useMemo(() => {
        const address = _get(provider, 'address[0].address');

        if (address) {
            address.city_id = address.city?.id;
            address.state_id = address.state?.id;
        }

        return {
            ..._omit(provider, ['address.state', 'address.city', 'type_business', 'juridic_name', 'juridic_fantasy_name', 'juridic_document', 'personal_document']),
            address,
            type_business_id: provider?.type_business?.id,
            ...(provider?.type === PersonContractType.Juridic && {
                juridic_name: provider.juridic_name,
                juridic_fantasy_name: provider.juridic_fantasy_name,
                juridic_document: provider.juridic_document
            }),
            ...(provider?.type === PersonContractType.Personal && {
                personal_document: provider.personal_document
            })
        };
    }, [provider]);

    return (
        <CompanyForm
            contactsWithUser={false}
            businessTypes={businessTypes || []}
            isBuilding={isBuilding}
            isSubmitting={isSubmitting || isLoadingFile}
            title={title || ''}
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            showImage={true}
            showPartner={true}
        />
    );
};

export default ProvidersCreateOrUpdatePage;
