import { useState } from 'react';
import Card from 'components/core/card';
import Text from 'components/core/text';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import ExternalButton from 'components/buttons/external';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import Empty from 'components/empty';
import AddButton from 'components/buttons/add';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useGetProjectDocuments from 'services/queries/projects/use-get-project-documents';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import useDeleteProjectDocument from 'services/queries/projects/use-delete-project-document';

type ProjectDocumentsProps = {
    withActions: boolean;
};

const warningButton = { classes: { child: 'text-system-warning-100' } };
const dangerButton = { classes: { child: 'text-system-danger-100' } };

const ProjectDocuments = ({ withActions }: ProjectDocumentsProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { projectId } = useParams();

    const [observation, setObservation] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [documentId, setDocumentId] = useState<number>();

    const { data: documents } = useGetProjectDocuments(projectId);
    const { mutateAsync: deleteDocument, isLoading: isDeletingDocument } = useDeleteProjectDocument(projectId);

    const handleToggleObservationsModal = (value?: string) => () => setObservation(value);

    const handleToggleDescriptionModal = (value?: string) => () => setDescription(value);

    const handleShowFile = (url: string) => () => window.open(url, '_blank');

    const handleDeleteDocument = async () => {
        try {
            await deleteDocument(documentId);

            setDocumentId(undefined);
        } catch (error) {
            console.log('handleDeleteDocument', error);
        }
    };

    return (
        <>
            <Card elevation={21} className="mb-4 px-6 py-5 relative">
                <Text as="h5" variant="h5" className="text-heading !text-base text-center sm:!text-left md:!text-lg xl:!text-xl relative">
                    Documentos
                </Text>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Título
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Categoria
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Descrição
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Observações
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Arquivo
                                    </Text>
                                </TableCell>
                                {withActions && (
                                    <TableCell classes={{ root: 'w-[130px]' }}>
                                        <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                            Ações
                                        </Text>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Boolean(documents?.length) ? (
                                documents?.map((item) => {
                                    return (
                                        <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell>
                                                <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                    {item.name || item.file.filename || ''}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                    {item.classification?.name || '-'}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                {Boolean(item.description) ? (
                                                    <ExternalButton onClick={handleToggleDescriptionModal(item.description)}>Visualizar</ExternalButton>
                                                ) : (
                                                    <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                        -
                                                    </Text>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {Boolean(item.description) ? (
                                                    <ExternalButton onClick={handleToggleObservationsModal(item.observation)}>Visualizar</ExternalButton>
                                                ) : (
                                                    <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                        -
                                                    </Text>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                    <ExternalButton onClick={handleShowFile(item.file.url)}>Visualizar</ExternalButton>
                                                </Text>
                                            </TableCell>
                                            {withActions && (
                                                <TableCell sx={{ minWidth: '150px' }}>
                                                    <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                                        <IconButton
                                                            TouchRippleProps={warningButton}
                                                            className="hover:bg-system-warning-100 hover:bg-opacity-30"
                                                            onClick={() => navigate(`documentos/${item.id}/editar`)}>
                                                            <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Apagar" disableFocusListener={true}>
                                                        <IconButton TouchRippleProps={dangerButton} className="hover:bg-system-danger-100 hover:bg-opacity-30" onClick={() => setDocumentId(item.id)}>
                                                            <Icon name="ico-trash" width={16} height={16} color={palette.error.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={4}>
                                        <Empty title="Nenhum documento encontrado" />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="mt-5 sm:mt-0 w-[100%] flex justify-center sm:block">
                    <AddButton linkComponent={Link} to="documentos/novo">
                        Novo documento
                    </AddButton>
                </div>
            </Card>
            {Boolean(observation) && (
                <Modal contentClassnames="py-5 px-7 w-[800px]" onClose={handleToggleObservationsModal(undefined)}>
                    <Text variant="h4" as="h4" className="text-heading mb-5">
                        Observações
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: observation! }} />
                </Modal>
            )}
            {Boolean(description) && (
                <Modal contentClassnames="py-5 px-7 w-[800px]" onClose={handleToggleDescriptionModal(undefined)}>
                    <Text variant="h4" as="h4" className="text-heading mb-5">
                        Descrição
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: description! }} />
                </Modal>
            )}
            {Boolean(documentId) && (
                <Modal contentClassnames="w-[500px]" closeOnClickOutside={false} onClose={() => setDocumentId(undefined)}>
                    <div className="p-6">
                        <Text as="h6" variant="h5" className="text-heading mb-3">
                            Apagar documento
                        </Text>
                        <Text as="p" variant="body.regular.sm" className="text-base-700 mb-6">
                            Você tem certeza que deseja apagar este documento?
                        </Text>
                        <div className="flex items-center justify-end">
                            <Button
                                disabled={isDeletingDocument}
                                loading={isDeletingDocument}
                                type="submit"
                                variant="contained"
                                color="error"
                                className="min-w-[100px] mr-4"
                                onClick={handleDeleteDocument}>
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={() => setDocumentId(undefined)}>
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default ProjectDocuments;
