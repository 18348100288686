// Helpers
import { BaseModel } from './base';
import { ProjectTypeDocument } from './project';

export type Classification = {
    classification_id: number;
    name: string;
    type: ClassificationType;
    project_type_documents: Partial<ProjectTypeDocument>[];
    classification: Classification;
    father?: {
        id: number;
        name: string;
    };
} & BaseModel;

export enum ClassificationType {
    Document = 1,
    Solicitation,
    Effective,
    WeatherCondition,
    ProviderServices,
    Asset,
    PersonDocument,
    ProjectDocument,
    Scope,
    FinancialClassification,
    ProjectSubject,
    Departament,
    TransactionDocument,
    Databook,
    ScopeComplementaryProjects,
    AssetTechnology,
    AssetFurniture
}
