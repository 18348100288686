import { useQuery } from 'react-query';

import apiGraphQl from 'services/graphql';

import queries, { getAllProjectServicesKey } from './graphql';
import { Option } from 'types/general';

type Response = {
    project_service: Option[];
};

const useGetProjectServicesOptions = () => {
    const fetcher = async () => apiGraphQl<Response>(queries.getProjectServicesOptions).then(({ project_service }) => project_service);

    return useQuery(getAllProjectServicesKey, fetcher);
};

export default useGetProjectServicesOptions;
